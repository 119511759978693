import * as Sentry from '@sentry/react';

const RUNNING_LOCALLY = window.location.hostname === 'localhost';
const SENTRY_ENABLED_FROM_ENV = process.env.REACT_APP_ENABLE_SENTRY === 'true';

if (SENTRY_ENABLED_FROM_ENV && !RUNNING_LOCALLY) {
	Sentry.init({
		environment: process.env.REACT_APP_ENVIRONMENT,
		dsn: 'https://1870ca38c3bf0884489aba07593cad61@o4508063937527808.ingest.de.sentry.io/4508068944674897',
		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.replayIntegration(),
			Sentry.captureConsoleIntegration({
				levels: ['error']
			})
		],
		// Tracing
		tracesSampleRate: 1.0, //  Capture 100% of the transactions
		// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: [
			'localhost',
			/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/?\n]+)\.ledidi\.no/i
		],
		// Session Replay
		replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});
}

export const configureSentryUser = (user: { sub: string }) => {
	Sentry.setUser({ id: user.sub });
};
