import { useMemo } from 'react';

import { Variable } from 'api/data/variables';
import { VariablesData } from 'store/data/variables';
import { VariablesDataSelectItems } from 'store/data/analyses';
import { RequireAtLeastOne, SelectItem, SelectGroup } from 'types/index';
import {
	buildVariablesRichData,
	variablesDataArrayIterator,
	getSeriesAggregationLabel,
	isGroupData
} from 'helpers/variables';
import { VariableType } from 'types/data/variables/constants';

export function useVariablesDataSelectItems(
	variablesData: VariablesData,
	options?: RequireAtLeastOne<{
		omitVariables?: string[];
		series?: string;
	}>
): VariablesDataSelectItems {
	const { omitVariables = [], series } = options ?? {};

	const data = useMemo<VariablesDataSelectItems>(() => {
		const { variablesDataArray, variablesMap } = buildVariablesRichData(variablesData);

		const data: VariablesDataSelectItems = {
			selectItemsMap: {},
			selectItems: [],
			numericSelectItems: [],
			durationSelectItems: [],
			integerSelectItems: [],
			categorySelectItems: [],
			categoryWithoutAggregationsSelectItems: [],
			categoryMultipleSelectItems: [],
			allCategorySelectItems: [],
			dateSelectItems: [],
			stringSelectItems: [],
			uniqueSelectItems: [],
			selectItemsLabelMap: {}
		};

		const allowedVariableTypes = [
			VariableType.Category,
			VariableType.CategoryMultiple,
			VariableType.Date,
			VariableType.DateTime,
			VariableType.Float,
			VariableType.Integer,
			VariableType.String,
			VariableType.TimeDuration,
			VariableType.Unique
		];

		variablesDataArrayIterator(
			variablesDataArray,
			// VARIABLE
			variable => {
				if (omitVariable(variable.name)) return;

				const variableSelectItem = getVariableSelectItem(variable);

				// ALL ALLOWED TYPES (EXCEPT: `file`, `status`)
				if (isVariableType(variable, allowedVariableTypes)) {
					data.selectItems.push(variableSelectItem);
				}

				// NUMERIC - FLOAT / INTEGER
				if (isVariableType(variable, [VariableType.Integer, VariableType.Float])) {
					data.numericSelectItems.push(variableSelectItem);
				}
				// INTEGER
				if (isVariableType(variable, VariableType.Integer)) {
					data.integerSelectItems.push(variableSelectItem);
				}
				// DURATION
				if (isVariableType(variable, VariableType.TimeDuration)) {
					data.durationSelectItems.push(variableSelectItem);
				}
				// CATEGORY
				if (isVariableType(variable, VariableType.Category)) {
					data.categorySelectItems.push(variableSelectItem);
					data.categoryWithoutAggregationsSelectItems.push(variableSelectItem);
				}
				// CATEGORY MULTIPLE
				if (isVariableType(variable, VariableType.CategoryMultiple)) {
					data.categoryMultipleSelectItems.push(variableSelectItem);
				}
				// CATEGORY AND CATEGORY MULTIPLE
				if (
					isVariableType(variable, VariableType.CategoryMultiple) ||
					isVariableType(variable, VariableType.Category)
				) {
					data.allCategorySelectItems.push(variableSelectItem);
				}
				// DATE
				if (isVariableType(variable, [VariableType.Date, VariableType.DateTime])) {
					data.dateSelectItems.push(variableSelectItem);
				}
				// STRING
				if (isVariableType(variable, VariableType.String)) {
					data.stringSelectItems.push(variableSelectItem);
				}
				// UNIQUE
				if (isVariableType(variable, VariableType.Unique)) {
					data.uniqueSelectItems.push(variableSelectItem);
				}

				data.selectItemsLabelMap[variableSelectItem.value] = variableSelectItem.label;
				data.selectItemsMap[variableSelectItem.value] = variableSelectItem;
			},
			// GROUP
			groupData => {
				const { groupLabel, groupVariables } = groupData;

				const groupSelectItem = getSelectGroup(groupLabel);
				const numericGroupSelectItem = getSelectGroup(groupLabel);
				const durationGroupSelectItem = getSelectGroup(groupLabel);
				const integerGroupSelectItem = getSelectGroup(groupLabel);
				const categoryGroupSelectItem = getSelectGroup(groupLabel);
				const categoryMultipleGroupSelectItem = getSelectGroup(groupLabel);
				const dateGroupSelectItem = getSelectGroup(groupLabel);
				const stringGroupSelectItem = getSelectGroup(groupLabel);

				groupVariables.forEach(variable => {
					if (omitVariable(variable.name)) return;

					const variableSelectItem = getVariableSelectItem(variable);

					// ALL ALLOWED TYPES (EXCEPT: `file`, `status`)
					if (isVariableType(variable, allowedVariableTypes)) {
						groupSelectItem.options.push(variableSelectItem);
					}

					// NUMERIC - FLOAT / INTEGER
					if (isVariableType(variable, [VariableType.Integer, VariableType.Float])) {
						numericGroupSelectItem.options.push(variableSelectItem);
					}
					// TIME DURATION
					if (isVariableType(variable, VariableType.TimeDuration)) {
						durationGroupSelectItem.options.push(variableSelectItem);
					}
					// INTEGER
					if (isVariableType(variable, VariableType.Integer)) {
						integerGroupSelectItem.options.push(variableSelectItem);
					}
					// CATEGORY
					if (isVariableType(variable, VariableType.Category)) {
						categoryGroupSelectItem.options.push(variableSelectItem);
					}
					// CATEGORY MULTIPLE
					if (isVariableType(variable, VariableType.CategoryMultiple)) {
						categoryMultipleGroupSelectItem.options.push(variableSelectItem);
					}
					// DATE
					if (isVariableType(variable, [VariableType.Date, VariableType.DateTime])) {
						dateGroupSelectItem.options.push(variableSelectItem);
					}
					// STRING
					if (isVariableType(variable, VariableType.String)) {
						stringGroupSelectItem.options.push(variableSelectItem);
					}

					data.selectItemsLabelMap[variableSelectItem.value] = variableSelectItem.label;
					data.selectItemsMap[variableSelectItem.value] = variableSelectItem;
				});

				if (groupSelectItem.options.length) {
					data.selectItems.push(groupSelectItem);
				}
				if (numericGroupSelectItem.options.length) {
					data.numericSelectItems.push(numericGroupSelectItem);
				}
				if (durationGroupSelectItem.options.length) {
					data.durationSelectItems.push(durationGroupSelectItem);
				}
				if (integerGroupSelectItem.options.length) {
					data.integerSelectItems.push(integerGroupSelectItem);
				}
				if (categoryGroupSelectItem.options.length) {
					data.categorySelectItems.push(categoryGroupSelectItem);
					data.categoryWithoutAggregationsSelectItems.push(categoryGroupSelectItem);
				}
				if (categoryMultipleGroupSelectItem.options.length) {
					data.categoryMultipleSelectItems.push(categoryMultipleGroupSelectItem);
				}
				if (dateGroupSelectItem.options.length) {
					data.dateSelectItems.push(dateGroupSelectItem);
				}
				if (stringGroupSelectItem.options.length) {
					data.stringSelectItems.push(stringGroupSelectItem);
				}
			},
			// VARIABLE SET DATA
			variableSetData => {
				const { setLabel, aggregationRules, setData, setName } = variableSetData;

				const setSelectItem = getSelectGroup(setLabel);
				const numericSetSelectItem = getSelectGroup(setLabel);
				const durationSetSelectItem = getSelectGroup(setLabel);
				const integerSetSelectItem = getSelectGroup(setLabel);
				const categorySetSelectItem = getSelectGroup(setLabel);
				const categoryMultipleSetSelectItem = getSelectGroup(setLabel);
				const allCategorySetSelectItem = getSelectGroup(setLabel);
				const dateSetSelectItem = getSelectGroup(setLabel);
				const stringSetSelectItem = getSelectGroup(setLabel);
				const uniqueSetSelectItem = getSelectGroup(setLabel);

				if (series && setName === series) {
					setData.forEach(item => {
						if (isGroupData(item)) {
							const { groupVariables, groupLabel } = item;

							const groupSelectItem = getSelectGroup(groupLabel);
							const numericGroupSelectItem = getSelectGroup(groupLabel);
							const durationGroupSelectItem = getSelectGroup(groupLabel);
							const integerGroupSelectItem = getSelectGroup(groupLabel);
							const categoryGroupSelectItem = getSelectGroup(groupLabel);
							const categoryMultipleGroupSelectItem = getSelectGroup(groupLabel);
							const dateGroupSelectItem = getSelectGroup(groupLabel);
							const stringGroupSelectItem = getSelectGroup(groupLabel);

							groupVariables.forEach(variable => {
								const variableSelectItem = getVariableSelectItem(variable);
								if (omitVariable(variable.name)) return;
								// [selected] series group variables will be grouped by the group name

								// ALL ALLOWED TYPES (EXCEPT: `file`, `status`)
								if (isVariableType(variable, allowedVariableTypes)) {
									groupSelectItem.options.push(variableSelectItem);
								}

								// NUMERIC - FLOAT / INTEGER
								if (
									isVariableType(variable, [
										VariableType.Integer,
										VariableType.Float
									])
								) {
									numericGroupSelectItem.options.push(variableSelectItem);
								}
								// TIME DURATION
								if (isVariableType(variable, VariableType.TimeDuration)) {
									durationGroupSelectItem.options.push(variableSelectItem);
								}
								// INTEGER
								if (isVariableType(variable, VariableType.Integer)) {
									integerGroupSelectItem.options.push(variableSelectItem);
								}
								// CATEGORY
								if (isVariableType(variable, VariableType.Category)) {
									categoryGroupSelectItem.options.push(variableSelectItem);
								}
								// CATEGORY MULTIPLE
								if (isVariableType(variable, VariableType.CategoryMultiple)) {
									categoryMultipleGroupSelectItem.options.push(
										variableSelectItem
									);
								}
								// DATE
								if (
									isVariableType(variable, [
										VariableType.Date,
										VariableType.DateTime
									])
								) {
									dateGroupSelectItem.options.push(variableSelectItem);
								}
								// STRING
								if (isVariableType(variable, VariableType.String)) {
									stringGroupSelectItem.options.push(variableSelectItem);
								}
							});

							if (groupSelectItem.options.length) {
								data.selectItems.push(groupSelectItem);
							}
							if (numericGroupSelectItem.options.length) {
								data.numericSelectItems.push(numericGroupSelectItem);
							}
							if (durationGroupSelectItem.options.length) {
								data.durationSelectItems.push(durationGroupSelectItem);
							}
							if (integerGroupSelectItem.options.length) {
								data.integerSelectItems.push(integerGroupSelectItem);
							}
							if (categoryGroupSelectItem.options.length) {
								data.categorySelectItems.push(categoryGroupSelectItem);
								data.categoryWithoutAggregationsSelectItems.push(
									categoryGroupSelectItem
								);
							}
							if (categoryMultipleGroupSelectItem.options.length) {
								data.categoryMultipleSelectItems.push(
									categoryMultipleGroupSelectItem
								);
							}
							if (dateGroupSelectItem.options.length) {
								data.dateSelectItems.push(dateGroupSelectItem);
							}
							if (stringGroupSelectItem.options.length) {
								data.stringSelectItems.push(stringGroupSelectItem);
							}
						} else {
							const variable = item;
							if (omitVariable(variable.name)) return;

							const variableSelectItem = getVariableSelectItem(variable);

							// ALL ALLOWED TYPES (EXCEPT: `file`, `status`)
							if (isVariableType(variable, allowedVariableTypes)) {
								setSelectItem.options.push(variableSelectItem);
							}

							// NUMERIC - FLOAT / INTEGER
							if (
								isVariableType(variable, [VariableType.Integer, VariableType.Float])
							) {
								numericSetSelectItem.options.push(variableSelectItem);
							}
							// INTEGER
							if (isVariableType(variable, VariableType.Integer)) {
								integerSetSelectItem.options.push(variableSelectItem);
							}
							// DURATION
							if (isVariableType(variable, VariableType.TimeDuration)) {
								durationSetSelectItem.options.push(variableSelectItem);
							}
							// CATEGORY
							if (isVariableType(variable, VariableType.Category)) {
								categorySetSelectItem.options.push(variableSelectItem);
							}
							// CATEGORY MULTIPLE
							if (isVariableType(variable, VariableType.CategoryMultiple)) {
								categoryMultipleSetSelectItem.options.push(variableSelectItem);
							}
							// CATEGORY AND CATEGORY MULTIPLE
							if (
								isVariableType(variable, VariableType.CategoryMultiple) ||
								isVariableType(variable, VariableType.Category)
							) {
								allCategorySetSelectItem.options.push(variableSelectItem);
							}
							// DATE
							if (
								isVariableType(variable, [VariableType.Date, VariableType.DateTime])
							) {
								dateSetSelectItem.options.push(variableSelectItem);
							}
							// STRING
							if (isVariableType(variable, VariableType.String)) {
								stringSetSelectItem.options.push(variableSelectItem);
							}
							// UNIQUE
							if (isVariableType(variable, VariableType.Unique)) {
								uniqueSetSelectItem.options.push(variableSelectItem);
							}

							data.selectItemsLabelMap[variableSelectItem.value] =
								variableSelectItem.label;
							data.selectItemsMap[variableSelectItem.value] = variableSelectItem;
						}

						data.selectItemsLabelMap[series] = setLabel;
					});
				}

				aggregationRules.forEach(aggregationRule => {
					// if (series) return;
					const { name, aggregator } = aggregationRule;

					if (omitVariable(name)) return;

					const variable = variablesMap[aggregator.variableName];
					const aggregatedVariableSetSelectItem: SelectItem = {
						label: getSeriesAggregationLabel(aggregationRule, variable),
						value: name
					};

					// ALL ALLOWED TYPES (EXCEPT: `file`, `status`)
					if (isVariableType(variable, allowedVariableTypes)) {
						setSelectItem.options.push(aggregatedVariableSetSelectItem);
					}

					// NUMERIC - FLOAT / INTEGER
					if (isVariableType(variable, [VariableType.Integer, VariableType.Float])) {
						numericSetSelectItem.options.push(aggregatedVariableSetSelectItem);
					}
					// TIME DURATION
					if (isVariableType(variable, VariableType.TimeDuration)) {
						durationSetSelectItem.options.push(aggregatedVariableSetSelectItem);
					}
					// INTEGER
					if (isVariableType(variable, VariableType.Integer)) {
						integerSetSelectItem.options.push(aggregatedVariableSetSelectItem);
					}
					// CATEGORY
					if (isVariableType(variable, VariableType.Category)) {
						categorySetSelectItem.options.push(aggregatedVariableSetSelectItem);
					}
					// CATEGORY
					if (isVariableType(variable, VariableType.CategoryMultiple)) {
						categoryMultipleSetSelectItem.options.push(aggregatedVariableSetSelectItem);
					}
					// DATE
					if (isVariableType(variable, [VariableType.Date, VariableType.DateTime])) {
						dateSetSelectItem.options.push(aggregatedVariableSetSelectItem);
					}
					// STRING
					if (isVariableType(variable, VariableType.String)) {
						stringSetSelectItem.options.push(aggregatedVariableSetSelectItem);
					}

					data.selectItemsLabelMap[aggregatedVariableSetSelectItem.value] =
						aggregatedVariableSetSelectItem.label;
					data.selectItemsMap[aggregatedVariableSetSelectItem.value] =
						aggregatedVariableSetSelectItem;
				});

				if (setSelectItem.options.length) {
					data.selectItems.push(setSelectItem);
				}
				if (numericSetSelectItem.options.length) {
					data.numericSelectItems.push(numericSetSelectItem);
				}
				if (durationSetSelectItem.options.length) {
					data.durationSelectItems.push(durationSetSelectItem);
				}
				if (integerSetSelectItem.options.length) {
					data.integerSelectItems.push(integerSetSelectItem);
				}
				if (categorySetSelectItem.options.length) {
					data.categorySelectItems.push(categorySetSelectItem);
				}
				if (categoryMultipleSetSelectItem.options.length) {
					data.categoryMultipleSelectItems.push(categoryMultipleSetSelectItem);
				}
				if (dateSetSelectItem.options.length) {
					data.dateSelectItems.push(dateSetSelectItem);
				}
				if (stringSetSelectItem.options.length) {
					data.stringSelectItems.push(stringSetSelectItem);
				}
			}
		);

		function omitVariable(variableName: string): boolean {
			return omitVariables.includes(variableName);
		}

		return data;
	}, [variablesData, omitVariables, series]);

	return data;
}

function getVariableSelectItem(variable: Variable): SelectItem {
	return { label: variable.label, value: variable.name };
}

function getSelectGroup(label: string): SelectGroup<SelectItem> {
	return { label: label, options: [] };
}

function isVariableType(variable: Variable, variableType: VariableType | VariableType[]): boolean {
	if (Array.isArray(variableType)) {
		const variableTypes = variableType;

		return variableTypes.includes(variable.type);
	}

	return variable.type === variableType;
}
