import { useEffect, useMemo } from 'react';

import { ExploreAnalysisV2 } from 'api/data/analyses';
import { AnalysisViewContainer, AnalysisErrorWrapper } from 'components/Analysis/Analyses';
import { VariablesDataSelectItems } from 'store/data/analyses';
import { ExportFileNames, FileType } from 'types/index';

import { ExploreTableV2 } from './ExploreTableV2';
import { exportExplore } from 'helpers/analysis';
import { downloadFile, getExportFileName } from 'helpers/generic';
import { useProject, useTimeDurationEntries, useTranslation } from 'hooks/store';
import { cloneDeep } from 'lodash';
import { useSelector } from 'hooks/utils';
import { selectTranslations } from 'store/ui/i18n';
import { VariablesData } from 'store/data/variables';
import { AnalysisType } from 'api/data/analyses/constants';

interface Props {
	analysis: ExploreAnalysisV2;
	variablesDataSelectItems: VariablesDataSelectItems;
	variablesData: VariablesData;
	exporting: boolean;
	loading: boolean;
}

export function ExploreViewV2({
	analysis,
	variablesDataSelectItems,
	variablesData,
	exporting,
	loading
}: Props) {
	const { translate } = useTranslation();
	const translations = useSelector(state => selectTranslations(state.ui.i18n));

	const [{ data: project }] = useProject();

	const { selectItemsLabelMap } = variablesDataSelectItems;
	const { parseTimeDurationEntryCell } = useTimeDurationEntries({ withTranslation: true });
	const { variablesMap, variableSetsMap } = variablesData;
	const {
		input,
		options: { configPanel },
		output: {
			dataset: { data: dataset }
		}
	} = analysis;

	const parsedDataset = useMemo(() => {
		const parsedDataset = cloneDeep(dataset ?? []);
		return parsedDataset;
	}, [translations, dataset, variablesMap, input.variables]);

	useEffect(() => {
		if (exporting && dataset) {
			const data = exportExplore(
				dataset,
				{ variablesMap, variableSetsMap },
				{
					translate,
					columnLabelsMap: selectItemsLabelMap
				},
				{ parseTimeDurationEntryCell }
			);

			if (data && project) {
				downloadFile(
					data,
					getExportFileName(
						ExportFileNames.ExploreTableV2,
						project.projectId,
						project.projectName
					),
					FileType.Csv
				);
			}
		}
	}, [exporting]);

	return (
		<AnalysisViewContainer
			isConfigPanelOpen={configPanel.open}
			loading={loading}
			error={!input.variables.length}
			message={translate(({ analysis }) => analysis.analyses.explore.view.chooseAVariable)}
		>
			<AnalysisErrorWrapper analysis={analysis} analysisType={AnalysisType.ExploreV2}>
				<ExploreTableV2 series={analysis.input.series} dataset={parsedDataset} />
			</AnalysisErrorWrapper>
		</AnalysisViewContainer>
	);
}
