import { useMemo } from 'react';
import { NumberMap } from 'types/index';
import { AnalysisContainer } from '../../UI';
import { NoPlot } from '../../NoPlot';
import { Flex } from 'components/UI/Flex';
import { Pagination } from 'components/UI/Pagination';
import { Table } from 'components/UI/Table';
import { withMemo } from 'helpers/HOCs';
import { decodeURIComponentSafe } from 'helpers/generic';
import { useTranslation } from 'hooks/store';
import { usePaginate } from 'hooks/utils';

interface Props {
	data: {
		keys: string[];
		dict: NumberMap;
	};
	loading: boolean;
}

function Component({ data, loading }: Props) {
	const { translate } = useTranslation();

	const { rows, sum } = useMemo(() => {
		let sum = 0;
		const { keys, dict } = data;
		const rows = [];

		for (let i = 0; i < keys.length; i++) {
			const key = keys[i];
			sum += dict[key];

			if (key === 'null-category-value') {
				rows.push({
					key: translate(({ analysis }) => analysis.analyses.frequencies.table.missing),
					value: dict[key]
				});
			} else {
				rows.push({ key, value: dict[key] });
			}
		}

		return { rows, sum };
	}, [data]);

	const { pageIndex, pageSize, pagesCount, shouldPaginate, page, changePage, changePageSize } =
		usePaginate(rows, {
			threshold: 10,
			pageSize: 10
		});

	const isEmpty = () => Object.values(data.dict).filter(value => value !== 0).length === 0;

	if (isEmpty() && !loading) {
		return (
			<AnalysisContainer>
				<NoPlot message={translate(({ analysis }) => analysis.errors.noResults)} />
			</AnalysisContainer>
		);
	}

	return (
		<>
			{shouldPaginate && (
				<Flex marginOffset={{ bottom: 0.4 }}>
					<Pagination
						totalCountLabel={translate(
							({ analysis }) => analysis.analyses.frequencies.table.values
						)}
						pageIndex={pageIndex}
						pageSize={pageSize}
						pagesCount={pagesCount}
						changePage={changePage}
						changePageSize={changePageSize}
						totalCount={rows.length}
					/>
				</Flex>
			)}

			<Table.Responsive horizontalScroll>
				<Table fullWidth>
					<Table.Head>
						<Table.Row>
							<Table.Column minWidth={15} maxWidth={20}>
								{translate(
									({ analysis }) => analysis.analyses.frequencies.table.category
								)}
							</Table.Column>
							<Table.Column minWidth={10}>
								{translate(({ analysis }) => analysis.analyses.frequencies.table.n)}
							</Table.Column>
							<Table.Column minWidth={10}>%</Table.Column>
						</Table.Row>
					</Table.Head>
					<Table.Body>
						{page.map(row => (
							<Row key={`frequencies-table-row-${row.key}`} row={row} sum={sum} />
						))}
					</Table.Body>
				</Table>
			</Table.Responsive>
		</>
	);
}

export const FrequenciesTableV2 = withMemo(Component);

interface RowProps {
	row: {
		key: string;
		value: number;
	};
	sum: number;
}

function Row({ row, sum }: RowProps) {
	const percent = ((row.value / sum) * 100).toFixed(1);
	const label = decodeURIComponentSafe(row.key);

	return (
		<Table.Row data-testid="frequencies-table-row">
			<Table.Cell title={label} maxWidth={20} noWrap>
				{label}
			</Table.Cell>
			<Table.Cell>{row.value}</Table.Cell>
			<Table.Cell>{!isNaN(Number(percent)) ? percent : ''}%</Table.Cell>
		</Table.Row>
	);
}
