import { cloneDeep } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { VariableType } from 'types/data/variables/constants';
import {
	ColumnTitle,
	MatchColumnToMainVariableContainer,
	NarrowContainer,
	PreviousMappingButton,
	Subtitle,
	Title
} from 'components/Projects/CreateAndImport';
import { ImportPageProps, PreviewVariable } from 'types/data/projects/import/types';
import { ActionTypes } from 'store/data/projects';
import { Svgs } from 'environment';
import { InputType, ImportType } from 'types/index';
import { SelectItem } from 'types/index';
import { PreviousMappingModal } from '../../PreviousMappingModal';
import { FlexCellLabel, FlexCellType, FlexRow, List, Spacer } from './DataToEntries.style';
import { Flex } from 'components/UI/Flex';
import { Typography } from 'components/UI/Typography';
import { Icon } from 'components/UI/Icons';
import { Input } from 'components/UI/Inputs/Input';
import { StickyFooter } from 'components/UI/StickyFooter';
import { CreatableSelect } from 'components/UI/Interactables/CreatableSelect';
import { useTranslation, useActivities, useVariables } from 'hooks/store';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { TimeZoneOptions } from '../TimeZoneOptions/TimeZoneOptions';
import { Spacer as VerticalSpacer } from 'components/UI/Spacer';
import { DATE_FORMATS } from 'types/data/projects/import/constants';
import {
	handleCustomDateFormatChange,
	handleVariableDateTypeChange,
	handleVariableTimeZoneChange,
	matchDateTimeFormat,
	showDateFormatPicker
} from 'helpers/projects/imports/importDateAndDatetime';
import {
	getErroredPreviewVariableLocationMapById,
	getPreviewVariablesAndTypes,
	shouldFocus
} from 'helpers/projects/imports/suggestedVariables';
import { useImportManager } from 'hooks/store/data/projects/import/useImportManager';
import {
	applyTimezoneToAllDatetimeVariables,
	importRequiresGlobalTimezoneSettings,
	variableHasSuggestedDateFormat
} from 'helpers/projects/imports/importTimezone';
import { useEffectOnce, useMaybeOutsideClick } from 'hooks/utils';
import { Pagination } from 'components/UI/Pagination';
import { dynamicTranslate } from 'helpers/dynamicTranslate';
import { Switch } from 'components/UI/Interactables/Switch';

export function DataToEntries({
	handleBack,
	handleFinishImport,
	handleApiImportErrors
}: ImportPageProps) {
	const [{ loading: loadingGenerateVariableNames }] = useActivities([
		ActionTypes.GENERATE_VARIABLE_NAMES
	]);

	const { translate } = useTranslation();
	const { addMoreColumnsImportError, timezoneDuringImport } = useFlags();

	const [{ fetched: areVariablesFetched }] = useVariables({ initial: true, lazy: true });

	const {
		data: {
			main: {
				scopeVariables,
				oldFormattedVariables,
				dataTypeSelectItems,
				initialNewVariables,
				dateFormatsSelectItems,
				dateTimeFormatsSelectItems,
				formattedVariables,
				globalTimezone,
				initialSuggestions,
				mappedMainLevelVariables,
				previousMapping,
				timeZones,
				variablesWithDateFormat,
				columnToMatch,
				variableToMatch,
				isBinary,
				isExcelOrigin
			},
			series: {
				columnToMatchWithMainLevel,
				isImportVariableSet,
				variableToMatchOnMainLevel,
				importVariableSetName
			},
			errors: { showOnlyErrors },
			refs: {
				dateInputRefs,
				dateTimeInputRefs,
				containerRefs,
				customFormatInputRefs,
				currentlyModifyingVarID,
				lastRefs
			}
		},
		handlers: {
			main: {
				canUpload,
				computeTitleAndSubtitle,
				handleChangeGlobalTimezone,
				onVariableTypeChanged,
				onVariableLabelChange,
				requiresTimezoneSettings,
				setOldFormattedVariables,
				setFormattedVariables,
				setColumnToMatch,
				setVariableToMatch,
				upload
			},
			series: {
				setDestinationSetName,
				handleSelectColumnToMatchWithMainLevel,
				setColumnToMatchWithMainLevel,
				setVariableToMatchOnMainLevel
			},
			errors: { setShowOnlyErrors, setErrors },
			refs: { getRefs, setCurrentlyModifyingVarID, setLastRefs }
		},
		pagination: {
			pageIndex,
			pageSize,
			pagesCount,
			shouldPaginate,
			page,
			changePage,
			changePageSize
		}
	} = useImportManager({
		selectedOption: ImportType.MoreDataToExistingEntries,
		handleFinishImport,
		handleApiImportErrors
	});

	const suggestedErroredVariableLocationById = useMemo(
		() => getErroredPreviewVariableLocationMapById(formattedVariables, pageSize),
		[formattedVariables, pageSize]
	);

	const errorsCount = useMemo(() => {
		let count = 0;
		Object.values(suggestedErroredVariableLocationById).forEach(
			varErrors => (count += Object.keys(varErrors).length)
		);

		return count;
	}, [suggestedErroredVariableLocationById]);

	useMaybeOutsideClick(() => {
		if (currentlyModifyingVarID) {
			setErrors();
		}
	}, lastRefs);

	// Focus on the last touched variable if needed
	useEffect(() => {
		if (currentlyModifyingVarID) {
			const variable = formattedVariables.find(v => v.id === currentlyModifyingVarID);
			if (!variable) return;
			if (!shouldFocus(variable)) {
				if (variable.customDateFormat) {
					const customRef = customFormatInputRefs.current[currentlyModifyingVarID];
					setLastRefs([customRef]);
					return;
				}
				setCurrentlyModifyingVarID(null);
				return;
			}
			const refs = getRefs(variablesWithDateFormat, initialSuggestions);
			if (!refs) {
				setCurrentlyModifyingVarID(null);
				return;
			}
			if (refs.some(r => !r)) return;
			if (lastRefs.length !== refs.length || lastRefs.some((r, i) => r !== refs[i])) {
				setLastRefs(refs);
			}
			refs[0].focus();
		}
	}, [currentlyModifyingVarID, formattedVariables]);

	const [showPreviousMappingModal, setShowPreviousMappingModal] = useState(false);

	const mappedOldFormattedVariables = oldFormattedVariables.map(variable => ({
		label: variable.label,
		value: variable.name
	}));

	const {
		importType: previousImportType,
		formattedVariables: previousFormattedVariables,
		columnToMatch: previousColumnToMatch,
		variableToMatch: previousVariableToMatch,
		columnToMatchWithMainLevel: previousColumnToMatchWithMainLevel,
		variableToMatchOnMainLevel: previousVariableToMatchOnMainLevel
	} = previousMapping;

	const isPreviousImportType = previousImportType === ImportType.MoreDataToExistingEntries;
	const canUsePreviousVariableToMatch = mappedOldFormattedVariables.some(
		variable =>
			variable.label === previousVariableToMatch?.label &&
			variable.value === previousVariableToMatch?.name
	);
	const canUsePreviousColumnToMatch = initialNewVariables.some(
		variable => variable.previewVariableLabel === previousColumnToMatch?.label
	);

	const canUsePreviousVariableToMatchOnMainLevel = mappedMainLevelVariables.some(
		variable =>
			variable.label === previousVariableToMatchOnMainLevel?.label &&
			variable.value === previousVariableToMatchOnMainLevel?.name
	);
	const canUsepreviousColumnToMatchWithMainLevel = initialNewVariables.some(
		variable => variable.previewVariableLabel === previousColumnToMatchWithMainLevel?.label
	);

	const getMainLevelMatching = isImportVariableSet
		? canUsePreviousVariableToMatchOnMainLevel && canUsepreviousColumnToMatchWithMainLevel
		: true;

	const availablePreviousFormattedVariables = previousFormattedVariables.filter(
		previousFormattedVariable =>
			initialNewVariables.some(
				variable =>
					variable.previewVariableLabel === previousFormattedVariable.previewVariableLabel
			)
	);
	const availablePreviousFormattedVariablesLabels = availablePreviousFormattedVariables.map(
		variable => variable.previewVariableLabel
	);
	const canUsePreviousMapping =
		getMainLevelMatching &&
		isPreviousImportType &&
		canUsePreviousVariableToMatch &&
		canUsePreviousColumnToMatch &&
		availablePreviousFormattedVariables.length > 0;

	// https://ledidi.atlassian.net/browse/PRJCTS-8688
	// time duration disabled altogether for this release (2023-12-15)
	const allowedDataTypeSelectItems: SelectItem[] = dataTypeSelectItems.filter(
		item => item.value !== VariableType.TimeDuration
	);

	const columnToMatchSeriesItems = initialNewVariables
		.filter(v => (columnToMatch ? v.id !== columnToMatch.id : true))
		.map(variable => ({
			label: variable.label,
			value: variable.name
		}));
	const columnToMatchMainItems = initialNewVariables
		.filter(v => (columnToMatchWithMainLevel ? v.id !== columnToMatchWithMainLevel.id : true))
		.map(variable => ({
			label: variable.label,
			value: variable.name
		}));

	useEffectOnce(() => {
		if (isImportVariableSet && importVariableSetName) {
			setDestinationSetName(importVariableSetName);
		}
	});

	useEffect(() => {
		if (scopeVariables.length) {
			setOldFormattedVariables(
				getPreviewVariablesAndTypes(
					scopeVariables.map(({ type, name, label }) => ({
						name,
						suggestedVariableType: type,
						isEmpty: false,
						label
					}))
				).variables
			);
		}
	}, [areVariablesFetched, scopeVariables]);

	function handleSelectColumn(index: number) {
		const initialValue = initialNewVariables[index];

		// ids to be excluded from preview variables
		const excludedIds = [columnToMatchWithMainLevel?.id, initialValue.id].filter(id => !!id);

		// merge initialNewVariables and newVariables but newVariables need to take precedence.
		const filteredVariables: PreviewVariable[] = [];

		initialNewVariables.forEach(initialNewVariable => {
			if (excludedIds.includes(initialNewVariable.id)) {
				return;
			}
			// if newVariable with this id already exists => return it because it contains updated info (name/type);
			const newVariable = formattedVariables.find(
				variable => variable.id === initialNewVariable.id
			);
			if (newVariable) {
				return filteredVariables.push(newVariable);
			}
			// else return initial variable -> we did not currently update this variable or it has been selected right now;
			filteredVariables.push(initialNewVariable);
		});

		setColumnToMatch({
			...initialValue,
			index
		});

		setFormattedVariables(filteredVariables);
	}

	function loadPreviousMapping() {
		previousColumnToMatch && setColumnToMatch(previousColumnToMatch);
		previousVariableToMatch && setVariableToMatch(previousVariableToMatch);

		previousColumnToMatchWithMainLevel &&
			setColumnToMatchWithMainLevel(previousColumnToMatchWithMainLevel);
		previousVariableToMatchOnMainLevel &&
			setVariableToMatchOnMainLevel(previousVariableToMatchOnMainLevel);

		const newFormattedVariables = cloneDeep(initialNewVariables);

		newFormattedVariables.slice(0).forEach(item => {
			if (
				item.label === previousColumnToMatch?.label ||
				item.label === previousColumnToMatchWithMainLevel?.label
			) {
				const index = newFormattedVariables.indexOf(item);
				newFormattedVariables.splice(index, 1);
			}
		});
		const variablesWithPreviousMapping = newFormattedVariables.map(
			newVariable =>
				availablePreviousFormattedVariables.find(
					availablePreviousFormattedVariable =>
						availablePreviousFormattedVariable.previewVariableLabel ===
						newVariable.previewVariableLabel
				) || newVariable
		);
		setFormattedVariables(variablesWithPreviousMapping);
	}

	const { title, subtitle } = computeTitleAndSubtitle();

	return (
		<>
			<NarrowContainer>
				{!addMoreColumnsImportError && <Title>{title}</Title>}
				<Subtitle>{subtitle}</Subtitle>

				{importRequiresGlobalTimezoneSettings(formattedVariables, timezoneDuringImport) && (
					<TimeZoneOptions
						value={globalTimezone}
						onChange={handleChangeGlobalTimezone}
						applyToAll={() =>
							applyTimezoneToAllDatetimeVariables(
								formattedVariables,
								globalTimezone,
								setFormattedVariables,
								translate
							)
						}
					/>
				)}

				{canUsePreviousMapping && (
					<Flex>
						<PreviousMappingButton
							variant={v => v.link}
							title={translate(
								({ projects }) =>
									projects.createAndImport.generics.previousMappingModal.title
							)}
							marginOffset={{ right: 2.4 }}
							onClick={() => setShowPreviousMappingModal(true)}
						/>
					</Flex>
				)}

				{isImportVariableSet && (
					<MatchColumnToMainVariableContainer>
						<FlexRow noMargin>
							<FlexCellLabel>
								<Typography.Caption fontweight={w => w.medium}>
									{translate(
										({ projects }) =>
											projects.createAndImport.generics.previewVariables
												.importVariableSet.mainFileColumn
									)}
								</Typography.Caption>
							</FlexCellLabel>
							<Spacer />
							<FlexCellType>
								<Typography.Caption fontweight={w => w.medium}>
									{translate(
										({ projects }) =>
											projects.createAndImport.generics.previewVariables
												.importVariableSet.mainVariableToMatch
									)}
								</Typography.Caption>
							</FlexCellType>
						</FlexRow>

						<FlexRow noMargin>
							<FlexCellLabel>
								<CreatableSelect
									id={`column-to-match-series-level`}
									canClear={false}
									placeholder={translate(
										dict => dict.projectsPage.dataToEntries.columnName
									)}
									className="select"
									scrollIntoView
									value={
										columnToMatchWithMainLevel
											? {
													label: columnToMatchWithMainLevel?.previewVariableLabel,
													value: columnToMatchWithMainLevel?.previewVariableLabel
											  }
											: null
									}
									items={columnToMatchSeriesItems}
									onValueSelected={val => {
										const index = initialNewVariables.findIndex(
											item => item.name === val
										);

										if (index === -1) {
											return;
										}

										handleSelectColumnToMatchWithMainLevel(index);
									}}
								/>
							</FlexCellLabel>
							<Spacer>
								<Icon svg={Svgs.ArrowLongRight} />
							</Spacer>
							<FlexCellType>
								<CreatableSelect
									canClear={false}
									className="select"
									placeholder={translate(
										dict => dict.projectsPage.dataToEntries.variableName
									)}
									value={
										variableToMatchOnMainLevel
											? {
													label: variableToMatchOnMainLevel.label,
													value: variableToMatchOnMainLevel.name
											  }
											: null
									}
									scrollIntoView
									items={mappedMainLevelVariables}
									onValueSelected={(_, __, item) =>
										item &&
										item.value &&
										setVariableToMatchOnMainLevel({
											name: item.value,
											label: item.label
										})
									}
								/>
							</FlexCellType>
						</FlexRow>
					</MatchColumnToMainVariableContainer>
				)}

				<Flex>
					<FlexCellLabel style={{ marginBottom: '1.2rem' }}>
						<Flex justify={j => j.between}>
							<Flex>
								<Switch
									onChange={() => {
										setShowOnlyErrors(!showOnlyErrors);
									}}
									on={showOnlyErrors}
									dataTestId="show-only-errors"
								/>
								<Typography.Paragraph multiline>
									{translate(
										dict =>
											dict.projects.createAndImport.generics.previewVariables
												.onlyVariablesWithErrors
									)}
								</Typography.Paragraph>
							</Flex>
							{Object.values(suggestedErroredVariableLocationById).length > 0 && (
								<div>
									<Typography.Error
										style={{ fontSize: '1.4rem' }}
										id="detected-errors-warning"
									>
										{dynamicTranslate(
											`${translate(
												dict =>
													dict.projects.createAndImport.generics
														.previewVariables.foundUnresolved
											)} ${
												errorsCount === 1
													? translate(
															dict => dict.import.importReview.error
													  )
													: translate(
															dict => dict.import.importReview.errors
													  )
											}`,
											[errorsCount.toString()]
										)}
									</Typography.Error>
								</div>
							)}
						</Flex>
					</FlexCellLabel>
				</Flex>

				{shouldPaginate && (
					<Flex marginOffset={{ bottom: 1.6 }}>
						<Pagination
							totalCountLabel={translate(dict => dict.terms.variables)}
							pageIndex={pageIndex}
							pageSize={pageSize}
							pagesCount={pagesCount}
							changePage={changePage}
							changePageSize={changePageSize}
							totalCount={formattedVariables.length}
						/>
					</Flex>
				)}

				<FlexRow noMargin>
					<FlexCellLabel>
						<Typography.Caption fontweight={w => w.medium}>
							{translate(
								({ projects }) =>
									projects.createAndImport.generics.dataToEntries.fileColumn
							)}
						</Typography.Caption>
					</FlexCellLabel>
					<Spacer />
					<FlexCellType>
						<Typography.Caption fontweight={w => w.medium}>
							{translate(
								({ projects }) =>
									projects.createAndImport.generics.dataToEntries.variableToMatch
							)}
						</Typography.Caption>
					</FlexCellType>
				</FlexRow>

				<List noMargin>
					<FlexRow noMargin>
						<FlexCellLabel>
							<CreatableSelect
								id={`column-to-match-main-level`}
								canClear={false}
								scrollIntoView
								placeholder={translate(
									dict => dict.projectsPage.dataToEntries.columnName
								)}
								value={
									columnToMatch
										? {
												label: columnToMatch?.label,
												value: columnToMatch?.label
										  }
										: null
								}
								items={columnToMatchMainItems}
								onValueSelected={val => {
									const index = initialNewVariables.findIndex(
										item => item.name === val
									);

									if (index === -1) {
										return;
									}

									handleSelectColumn(index);
								}}
							/>
						</FlexCellLabel>
						<Spacer>
							<Icon svg={Svgs.ArrowLongRight} />
						</Spacer>

						<FlexCellType>
							<CreatableSelect
								id={'variable-to-match'}
								canClear={false}
								scrollIntoView
								items={mappedOldFormattedVariables}
								placeholder={translate(
									dict => dict.projectsPage.dataToEntries.variableName
								)}
								value={
									variableToMatch
										? {
												value: variableToMatch.name,
												label: variableToMatch.label
										  }
										: null
								}
								onValueSelected={(_, __, item) => {
									item &&
										item.value &&
										setVariableToMatch({
											name: item.value,
											label: item.label
										});
								}}
							/>
						</FlexCellType>
					</FlexRow>
				</List>
				<FlexRow extraMarginTop>
					<FlexCellLabel>
						<ColumnTitle>
							{translate(
								({ projects }) =>
									projects.createAndImport.generics.previewVariables
										.newVariableLabel
							)}
						</ColumnTitle>
					</FlexCellLabel>
					<Spacer />
					<FlexCellType>
						<ColumnTitle>
							{translate(
								({ projects }) =>
									projects.createAndImport.generics.previewVariables.dataType
							)}
						</ColumnTitle>
					</FlexCellType>
				</FlexRow>
				<List>
					{page.map(variable => (
						<div
							key={variable.id}
							id={
								variable.type === VariableType.Date
									? `preview-variable-cell-date-format-${variable.id}`
									: `preview-variable-cell-date-time-format-${variable.id}`
							}
						>
							<FlexRow>
								<FlexCellLabel>
									<Input
										type={InputType.Text}
										value={variable.label}
										error={variable.labelError}
										id={`preview-variable-label-${variable.id}`}
										placeholder={translate(
											({ projects }) =>
												projects.createAndImport.generics.previewVariables
													.variableNameHere
										)}
										onChange={e => {
											onVariableLabelChange({
												varId: variable.id,
												value: e.target.value
											});
										}}
									/>
									{[VariableType.Date, VariableType.DateTime].includes(
										variable.type as VariableType
									) && (
										<>
											{variableHasSuggestedDateFormat(
												variable,
												formattedVariables,
												isExcelOrigin
											) ? (
												<>
													<VerticalSpacer size={s => s.s} />
													<CreatableSelect
														scrollIntoView
														canClear={!!variable.dateFormat}
														_ref={el =>
															variable.type === VariableType.Date
																? el &&
																  (dateInputRefs.current[
																		variable.id
																  ] = el)
																: el &&
																  (dateTimeInputRefs.current[
																		variable.id
																  ] = el)
														}
														containerRef={el =>
															el &&
															(containerRefs.current[variable.id] =
																el)
														}
														id={
															variable.type === VariableType.Date
																? `preview-variable-date-format-${variable.id}`
																: `preview-variable-date-time-format-${variable.id}`
														}
														disabled={
															(variablesWithDateFormat.includes(
																variable.previewVariableLabel
															) ||
																variablesWithDateFormat.includes(
																	variable.label
																)) &&
															initialSuggestions?.find(
																v =>
																	v.name ===
																		variable.previewVariableLabel ||
																	v.name === variable.label
															)?.suggestedVariableType ===
																variable.type
														}
														value={{
															label: matchDateTimeFormat(
																variable.dateFormat
															),
															value: matchDateTimeFormat(
																variable.dateFormat
															)
														}}
														items={
															variable.type === VariableType.Date
																? [
																		...dateFormatsSelectItems,
																		{
																			label: 'Custom',
																			value: 'Custom'
																		}
																  ]
																: [
																		...dateTimeFormatsSelectItems,
																		{
																			label: 'Custom',
																			value: 'Custom'
																		}
																  ]
														}
														onValueSelected={value => {
															value &&
																handleVariableDateTypeChange(
																	variable.id,
																	value,
																	formattedVariables,
																	setFormattedVariables,
																	translate,
																	globalTimezone
																);
															setCurrentlyModifyingVarID(variable.id);
														}}
														onClear={() => {
															handleVariableDateTypeChange(
																variable.id,
																'',
																formattedVariables,
																setFormattedVariables,
																translate,
																globalTimezone
															);
															setCurrentlyModifyingVarID(variable.id);
														}}
													/>
												</>
											) : (
												<>
													{!isExcelOrigin &&
														showDateFormatPicker(
															variable.type as VariableType,
															VariableType.Date,
															!!isExcelOrigin,
															isBinary
														) && (
															<>
																<VerticalSpacer size={s => s.s} />
																<CreatableSelect
																	id={`preview-variable-date-format-${variable.id}`}
																	canClear={!!variable.dateFormat}
																	_ref={el =>
																		el &&
																		(dateInputRefs.current[
																			variable.id
																		] = el)
																	}
																	containerRef={el =>
																		el &&
																		(containerRefs.current[
																			variable.id
																		] = el)
																	}
																	disabled={
																		(variablesWithDateFormat.includes(
																			variable.previewVariableLabel
																		) ||
																			variablesWithDateFormat.includes(
																				variable.label
																			)) &&
																		initialSuggestions?.find(
																			v =>
																				v.name ===
																					variable.previewVariableLabel ||
																				v.name ===
																					variable.label
																		)?.suggestedVariableType ===
																			variable.type
																	}
																	placeholder={translate(
																		dict =>
																			dict.projectsPage
																				.dataToEntries
																				.selectDateFormat
																	)}
																	value={
																		variable.dateFormat
																			? {
																					value: matchDateTimeFormat(
																						variable.dateFormat
																					),
																					label: matchDateTimeFormat(
																						variable.dateFormat
																					)
																			  }
																			: {
																					value: translate(
																						dict =>
																							dict
																								.projectsPage
																								.dataToEntries
																								.selectDateFormat
																					),
																					label: translate(
																						dict =>
																							dict
																								.projectsPage
																								.dataToEntries
																								.selectDateFormat
																					)
																			  }
																	}
																	items={[
																		{
																			label: 'Custom',
																			value: 'Custom'
																		},
																		...dateFormatsSelectItems
																	]}
																	onValueSelected={value => {
																		value &&
																			handleVariableDateTypeChange(
																				variable.id,
																				value,
																				formattedVariables,
																				setFormattedVariables,
																				translate,
																				globalTimezone
																			);
																		setCurrentlyModifyingVarID(
																			variable.id
																		);
																	}}
																	onClear={() => {
																		handleVariableDateTypeChange(
																			variable.id,
																			'',
																			formattedVariables,
																			setFormattedVariables,
																			translate,
																			globalTimezone
																		);
																		setCurrentlyModifyingVarID(
																			variable.id
																		);
																	}}
																	error={
																		variable.dateFormat !==
																			DATE_FORMATS.Custom &&
																		variable.dateFormatError
																			? variable.dateFormatError
																			: ''
																	}
																/>
															</>
														)}

													{showDateFormatPicker(
														variable.type as VariableType,
														VariableType.DateTime,
														!!isExcelOrigin,
														isBinary
													) && (
														<>
															<VerticalSpacer size={s => s.s} />
															<CreatableSelect
																id={`preview-variable-date-time-format-${variable.id}`}
																canClear={!!variable.dateFormat}
																_ref={el =>
																	el &&
																	(dateTimeInputRefs.current[
																		variable.id
																	] = el)
																}
																containerRef={el =>
																	el &&
																	(containerRefs.current[
																		variable.id
																	] = el)
																}
																disabled={
																	(variablesWithDateFormat.includes(
																		variable.previewVariableLabel
																	) ||
																		variablesWithDateFormat.includes(
																			variable.label
																		)) &&
																	initialSuggestions?.find(
																		v =>
																			v.name ===
																				variable.previewVariableLabel ||
																			v.name ===
																				variable.label
																	)?.suggestedVariableType ===
																		variable.type
																}
																placeholder={translate(
																	dict =>
																		dict.projectsPage
																			.dataToEntries
																			.selectDateAndTime
																)}
																value={
																	variable.dateFormat
																		? {
																				value: matchDateTimeFormat(
																					variable.dateFormat
																				),
																				label: matchDateTimeFormat(
																					variable.dateFormat
																				)
																		  }
																		: {
																				value: translate(
																					dict =>
																						dict
																							.projectsPage
																							.dataToEntries
																							.selectDateAndTime
																				),
																				label: translate(
																					dict =>
																						dict
																							.projectsPage
																							.dataToEntries
																							.selectDateAndTime
																				)
																		  }
																}
																items={[
																	{
																		label: 'Custom',
																		value: 'Custom'
																	},
																	...dateTimeFormatsSelectItems
																]}
																onValueSelected={(_, __, item) => {
																	item &&
																		item.value &&
																		handleVariableDateTypeChange(
																			variable.id,
																			item.value,
																			formattedVariables,
																			setFormattedVariables,
																			translate,
																			globalTimezone
																		);
																	setCurrentlyModifyingVarID(
																		variable.id
																	);
																}}
																onClear={() => {
																	handleVariableDateTypeChange(
																		variable.id,
																		'',
																		formattedVariables,
																		setFormattedVariables,
																		translate,
																		globalTimezone
																	);
																	setCurrentlyModifyingVarID(
																		variable.id
																	);
																}}
																error={
																	variable.dateFormat !==
																		DATE_FORMATS.Custom &&
																	variable.dateFormatError
																		? variable.dateFormatError
																		: ''
																}
															/>
														</>
													)}
												</>
											)}
											{variable.dateFormat === DATE_FORMATS.Custom && (
												<>
													<VerticalSpacer size={s => s.s} />
													<Input
														type={InputType.Text}
														ref={el =>
															el &&
															(customFormatInputRefs.current[
																variable.id
															] = el as HTMLInputElement)
														}
														id={`preview-variable-custom-date-format-${variable.id}`}
														value={variable.customDateFormat}
														error={variable.dateFormatError}
														placeholder={translate(
															dict =>
																dict.projectsPage.dataToEntries
																	.customDateFormat
														)}
														onChange={e => {
															handleCustomDateFormatChange(
																variable.id,
																e.target.value.toUpperCase(),
																formattedVariables,
																setFormattedVariables,
																translate,
																variable.type ===
																	VariableType.DateTime
															);
															setCurrentlyModifyingVarID(variable.id);
														}}
													/>
												</>
											)}
										</>
									)}
								</FlexCellLabel>
								<Spacer />
								<FlexCellType>
									<CreatableSelect
										canClear={false}
										items={allowedDataTypeSelectItems}
										placeholder={translate(
											({ inputPlaceholder }) => inputPlaceholder.pleaseSelect
										)}
										value={allowedDataTypeSelectItems.find(
											item => item.value === variable.type
										)}
										id={`preview-variable-type-${variable.id}`}
										onValueSelected={value => {
											value &&
												onVariableTypeChanged(
													variable.id,
													value as VariableType
												);
										}}
									/>

									{requiresTimezoneSettings(variable) && (
										<>
											<VerticalSpacer size={s => s.s} />
											<CreatableSelect
												id={`global_timezone_select`}
												items={timeZones}
												placeholder={translate(
													dict =>
														dict.projects.createAndImport.generics
															.previewVariables.timeZonePlaceholder
												)}
												onClear={() =>
													handleVariableTimeZoneChange(
														variable.id,
														'',
														formattedVariables,
														setFormattedVariables,
														translate
													)
												}
												onValueSelected={value =>
													value &&
													handleVariableTimeZoneChange(
														variable.id,
														value,
														formattedVariables,
														setFormattedVariables,
														translate
													)
												}
												value={
													variable.timeZone && variable.timeZone.value
														? variable.timeZone
														: null
												}
												error={variable.timeZoneError}
											/>
										</>
									)}
								</FlexCellType>
							</FlexRow>
						</div>
					))}
				</List>
			</NarrowContainer>

			<StickyFooter
				primary={{
					label: translate(({ buttons }) => buttons.continue),
					loading: loadingGenerateVariableNames,
					disabled: canUpload(),
					onClick: upload
				}}
				neutral={{
					label: translate(({ buttons }) => buttons.back),
					onClick: handleBack
				}}
				maxWidth={65.2}
				zIndex={1006}
			/>

			{showPreviousMappingModal && (
				<PreviousMappingModal
					previousVariablesLabels={availablePreviousFormattedVariablesLabels}
					onConfirm={() => {
						loadPreviousMapping();
						setShowPreviousMappingModal(false);
					}}
					onClose={() => setShowPreviousMappingModal(false)}
				/>
			)}
		</>
	);
}
