import { useEffect, useMemo } from 'react';
import { isEqual, cloneDeep } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { isAfter, isBefore, isEqual as isDateEqual } from 'date-fns';

import { ROUTES } from 'types/navigation';
import { VariableType } from 'types/data/variables/constants';
import { VariableFilteringMap, DynamicFormValues, DynamicFormValue } from 'store/data/entries';
import { BooleanMap, SetState, StringArrayMap, GenericMap, NumberMap } from 'types/index';
import { DependencyType, DependencyOperators, Dependency } from 'store/data/dependencies';
import {
	entryFormFieldChangeEvent,
	entryFormDependenciesCheckEvent,
	getMicrosecondsFromTimeDurationString
} from 'helpers/entries';
import { useCustomEventListener } from 'helpers/events';
import { debuggerLog } from 'helpers/generic';
import { withMemo } from 'helpers/HOCs';
import { useRouteMatch } from 'hooks/navigation';
import {
	useTranslation,
	useEntry,
	useSelectedSeriesEntry,
	useVariables,
	useDependencies,
	useRevision,
	useEntries
} from 'hooks/store';
import { useDeepCompareMemo, usePrevious } from 'hooks/utils';
import { TimeDurationKey } from 'timeDurationConsts';
import {
	buildDependencyNamesByVariableName,
	getDependenciesNamesWhichIncludeVariableName,
	getDependantVariables
} from 'helpers/dependencies';

interface DependenciesMapCheckerProps {
	variableVisibilityMapState: {
		variableVisibilityMap: BooleanMap;
		setVariableVisibilityMap: SetState<BooleanMap>;
	};
	variableFilteringMapState: {
		variableFilteringMap: VariableFilteringMap;
		setVariableFilteringMap: SetState<VariableFilteringMap>;
	};
	setName?: string;
	customFormContext?: {
		getValues: () => DynamicFormValues;
		setValue: (name: string, value: DynamicFormValue) => void;
	};
	dataTestIdEntryNumber?: number;
}

function DependenciesMapCheckerComponent({
	variableVisibilityMapState: { variableVisibilityMap, setVariableVisibilityMap },
	variableFilteringMapState: { variableFilteringMap, setVariableFilteringMap },
	setName,
	customFormContext
}: DependenciesMapCheckerProps) {
	const { translate } = useTranslation();
	// SET TO `true` TO SEE THE LOGS
	const DEBUGGER = false;
	const log = debuggerLog(DEBUGGER);

	const isOnUpdateEntryRoute = useRouteMatch([ROUTES.UpdateEntry, ROUTES.UpdatePromEntry]);

	const [{ data: entry }] = useEntry({ lazy: true });
	const [{ fetched: areEntriesFetched }] = useEntries();

	const [{ subEntryId }] = useSelectedSeriesEntry();

	const [
		{
			data: { variablesMap },
			fetched: areVariablesFetched
		}
	] = useVariables({ initial: true, lazy: true });

	const [
		{
			data: { active, dependencies, dependenciesBySetName },
			fetched: areDependenciesFetched
		}
	] = useDependencies({
		lazy: true
	});

	const [{ data: revision }] = useRevision({ lazy: true });

	const { active: scopeActive, dependencies: scopeDependencies } = useMemo(() => {
		const scope = {
			active,
			dependencies
		};

		if (setName) {
			scope.active = true;
			scope.dependencies = [];

			const setDependenciesData = dependenciesBySetName[setName];

			if (setDependenciesData) {
				scope.active = setDependenciesData.active;
				scope.dependencies = setDependenciesData.dependencies;
			}
		}

		return scope;
	}, [dependencies, dependenciesBySetName, setName]);

	const dependenciesMap = useMemo(
		() => buildDependenciesMap(scopeDependencies),
		[scopeDependencies]
	);
	const dependencyNames = useMemo(
		() => scopeDependencies.map(d => d.dependencyName),
		[scopeDependencies]
	);
	const dependencyNamesByVariableName = useMemo(
		() => buildDependencyNamesByVariableName(scopeDependencies),
		[scopeDependencies]
	);

	const { setValue: setValueFromNativeContext, getValues: getValuesFromNativeContext } =
		useFormContext<DynamicFormValues>();
	const { setValue, getValues } = useDeepCompareMemo(() => {
		if (!customFormContext) {
			return { setValue: setValueFromNativeContext, getValues: getValuesFromNativeContext };
		}

		return {
			setValue: customFormContext.setValue,
			getValues: customFormContext.getValues
		};
	}, [customFormContext]);

	function runDependenciesCheck(
		dependencyNames: string[],
		options?: { shouldResetFields?: boolean }
	) {
		log('runDependenciesCheck()', { dependencyNames });

		const { shouldResetFields = true } = options ?? {};

		const initialVisibilityMap = cloneDeep(variableVisibilityMap);
		const initialFilteringMap = cloneDeep(variableFilteringMap);

		let computedDependencyNames = [...dependencyNames];
		// inject dependencies associated to current involved variables following this rule:
		// if initial dependencies include a dependant that is part of other dependencies => include the other dependencies in the check

		dependencyNames.forEach(dep => {
			const dependency = dependenciesMap[dep];
			const dependantVariableNames = dependency.dependantVariables.map(
				variable => variable.dependantVariableName
			);

			dependantVariableNames.forEach(variableName => {
				const relatedDependenciesNames = getDependenciesNamesWhichIncludeVariableName({
					variableName,
					dependenciesMap
				});
				computedDependencyNames = [
					...new Set([...computedDependencyNames, ...relatedDependenciesNames])
				].sort((depName1, _) => {
					if (dependenciesMap[depName1].dependencyType === DependencyType.Visibility)
						return -1;
					return 1;
				});
			});
		});

		// new maps should only clear affected variable names;
		const affectedVariables = getDependantVariables(
			computedDependencyNames.map(name => dependenciesMap[name])
		);

		affectedVariables.forEach(name => {
			if (name in initialFilteringMap) {
				delete initialFilteringMap[name];
			}
			if (name in initialVisibilityMap) {
				delete initialVisibilityMap[name];
			}
		});
		const newVariableVisibilityMap: BooleanMap = cloneDeep(initialVisibilityMap);
		const newVariableFilteringMap: StringArrayMap = cloneDeep(initialFilteringMap);

		const formValues = cloneDeep(getValues());

		const fieldsToReset: string[] = [];

		computedDependencyNames.forEach(dependencyName => {
			const dependency = dependenciesMap[dependencyName];

			const { supplierVariableName, dependencyType, dependantVariables } = dependency;

			const supplierVariable = variablesMap[supplierVariableName];

			// VARIABLE DOES NOT EXIST - DO NOTHING
			if (!supplierVariable) return;

			const hasFormValue = supplierVariableName in formValues;

			// NO VALUE IN CURRENT FORM - DO NOTHING
			if (!hasFormValue) return;

			const formValue = formValues[supplierVariableName];

			const supplierValue = formValue as string;
			const isSupplierValueValid = supplierValue !== '';

			const supplierValues = formValue as string[];
			const areSupplierValuesValid = supplierValues.length > 0;

			const supplierVariableType = supplierVariable.type;

			const isIntegerVariableType = supplierVariableType === VariableType.Integer;
			const isFloatVariableType = supplierVariableType === VariableType.Float;
			const isNumericVariableType = isIntegerVariableType || isFloatVariableType;
			const isTimeDurationType =
				supplierVariableType === VariableType.TimeDuration &&
				supplierVariable.durationFormat;
			const isStringVariableType = supplierVariableType === VariableType.String;
			const isDateVariableType = [VariableType.Date, VariableType.DateTime].includes(
				supplierVariableType
			);
			const isCategoryVariableType = supplierVariableType === VariableType.Category;
			const isCategoryMultipleVariableType =
				supplierVariableType === VariableType.CategoryMultiple;

			const isVisibilityCondition = dependencyType === DependencyType.Visibility;
			const isFilteringCondition = dependencyType === DependencyType.Filtering;

			/**
			 * Number of times the same `dependantVariableName` matched
			 */
			const matchedTimes: NumberMap = {};
			dependantVariables.forEach(dependant => {
				const { dependantVariableName, supplierValueCondition, operator, filteredValues } =
					dependant;

				const dependantVariable = variablesMap[dependantVariableName];

				// VARIABLE DOES NOT EXIST - DO NOTHING
				if (!dependantVariable) return;

				const hasFormValue = dependantVariableName in formValues;

				// NO VALUE IN CURRENT FORM - DO NOTHING
				if (!hasFormValue) return;

				const formValue = formValues[dependantVariableName];

				const dependantVariableType = dependantVariable.type;

				const isDependantCategoryMultiple =
					dependantVariableType === VariableType.CategoryMultiple;

				const isSupplierValueConditionValid = supplierValueCondition !== '';

				const dependantVariableValue = formValue as string;
				const dependantVariableValues = formValue as string[];

				let conditionMatched = false;

				// TIME DURATION
				if (isTimeDurationType) {
					const parsedNumber = getMicrosecondsFromTimeDurationString(
						supplierValue,
						supplierVariable.durationFormat as TimeDurationKey[]
					);

					const parsedCondition = getMicrosecondsFromTimeDurationString(
						supplierValueCondition,
						supplierVariable.durationFormat as TimeDurationKey[]
					);

					const areValuesValid = isSupplierValueValid && isSupplierValueConditionValid;

					// <
					if (operator === DependencyOperators.LESS_THAN) {
						conditionMatched = areValuesValid && parsedNumber < parsedCondition;
					}
					// <=
					if (operator === DependencyOperators.LESS_OR_EQUAL_TO) {
						conditionMatched = areValuesValid && parsedNumber <= parsedCondition;
					}
					// ===
					if (operator === DependencyOperators.EQUAL_TO) {
						conditionMatched = areValuesValid && parsedNumber === parsedCondition;
					}
					// >=
					if (operator === DependencyOperators.GREATER_OR_EQUAL_TO) {
						conditionMatched = areValuesValid && parsedNumber >= parsedCondition;
					}
					// >
					if (operator === DependencyOperators.GREATER_THAN) {
						conditionMatched = areValuesValid && parsedNumber > parsedCondition;
					}
				}

				// NUMERIC (INTEGER / FLOAT)
				if (isNumericVariableType) {
					const parseNumber = isIntegerVariableType ? parseInt : parseFloat;

					const areValuesValid = isSupplierValueValid && isSupplierValueConditionValid;

					// <
					if (operator === DependencyOperators.LESS_THAN) {
						conditionMatched =
							areValuesValid &&
							parseNumber(supplierValue) < parseNumber(supplierValueCondition);
					}
					// <=
					if (operator === DependencyOperators.LESS_OR_EQUAL_TO) {
						conditionMatched =
							areValuesValid &&
							parseNumber(supplierValue) <= parseNumber(supplierValueCondition);
					}
					// ===
					if (operator === DependencyOperators.EQUAL_TO) {
						conditionMatched =
							areValuesValid &&
							parseNumber(supplierValue) === parseNumber(supplierValueCondition);
					}
					// >=
					if (operator === DependencyOperators.GREATER_OR_EQUAL_TO) {
						conditionMatched =
							areValuesValid &&
							parseNumber(supplierValue) >= parseNumber(supplierValueCondition);
					}
					// >
					if (operator === DependencyOperators.GREATER_THAN) {
						conditionMatched =
							areValuesValid &&
							parseNumber(supplierValue) > parseNumber(supplierValueCondition);
					}
				}

				// STRING
				if (isStringVariableType) {
					conditionMatched = supplierValue.includes(supplierValueCondition);
				}

				// DATE
				if (isDateVariableType) {
					const areValuesValid = isSupplierValueValid && isSupplierValueConditionValid;

					const left = new Date(supplierValue);
					const right = new Date(supplierValueCondition);

					// BEFORE DATE
					if (operator === DependencyOperators.LESS_THAN) {
						conditionMatched = areValuesValid && isBefore(left, right);
					}
					// EXACT DATE
					if (operator === DependencyOperators.EQUAL_TO) {
						conditionMatched = areValuesValid && isDateEqual(left, right);
					}
					// AFTER DATE
					if (operator === DependencyOperators.GREATER_THAN) {
						conditionMatched = areValuesValid && isAfter(left, right);
					}
				}

				// CATEGORY
				if (isCategoryVariableType) {
					const areValuesValid = isSupplierValueValid && isSupplierValueConditionValid;

					conditionMatched = areValuesValid && supplierValue === supplierValueCondition;
				}

				// CATEGORY MULTIPLE
				if (isCategoryMultipleVariableType) {
					const areValuesValid = areSupplierValuesValid && isSupplierValueConditionValid;

					conditionMatched =
						areValuesValid && supplierValues.includes(supplierValueCondition);
				}

				////////////////////////////////////////////////////////////////////////////////////
				////////////////////////////////////////////////////////////////////////////////////

				const matchedPrevVariable = dependantVariableName in matchedTimes;

				// KEEP TRACK OF HOW MANY TIMES SAME `dependantVariableName` MATCHED
				if (conditionMatched) {
					if (matchedPrevVariable) {
						matchedTimes[dependantVariableName]++;
					} else {
						matchedTimes[dependantVariableName] = 1;
					}
				}

				// VISIBILITY CONDITION
				if (isVisibilityCondition) {
					const isVisible = conditionMatched || matchedPrevVariable;

					if (shouldResetFields) {
						const shouldResetField =
							!newVariableVisibilityMap[dependantVariableName] && !isVisible;

						if (shouldResetField) {
							fieldsToReset.push(dependantVariableName);
							// SYNC LOCAL `formValues`
							formValues[dependantVariableName] =
								getFieldDefaultValue(dependantVariableName);
						}
					}

					// MATCH ANY VISIBILITY CASE
					newVariableVisibilityMap[dependantVariableName] = isVisible;
				}

				// FILTERING CONDITION
				if (isFilteringCondition) {
					if (conditionMatched) {
						newVariableFilteringMap[dependantVariableName] = arrayIntersection(
							filteredValues,
							newVariableFilteringMap[dependantVariableName] ?? filteredValues
						);

						/**
						 * APPLY FILTERING AND RESET FIELD IF NEEDED
						 */
						if (shouldResetFields) {
							// CATEGORY MULTIPLE - STRING []
							if (isDependantCategoryMultiple) {
								/**
								 * RESET FORM VALUES THAT ARE FILTERED
								 */
								const newValues = dependantVariableValues.filter(value =>
									filteredValues.includes(value)
								);

								setValue(dependantVariableName, newValues, {
									shouldDirty: true,
									shouldValidate: true
								});
								// SYNC LOCAL `formValues`
								formValues[dependantVariableName] = newValues;
							}
							// CATEGORY - STRING
							else {
								// LOGIC
								// should not reset category variable with unassigned value!
								const shouldResetField =
									!filteredValues.includes(dependantVariableValue) &&
									dependantVariableValue !== '';

								if (shouldResetField) {
									fieldsToReset.push(dependantVariableName);
									// SYNC LOCAL `formValues`
									formValues[dependantVariableName] =
										getFieldDefaultValue(dependantVariableName);
								}
							}
						}
					}
				}
			});
		});

		const uniqueFieldsToReset = [...new Set(fieldsToReset)];
		/**
		 * Reset the fields that are:
		 * - not visible anymore
		 * - category values are not included in the filtering options
		 */
		resetFields(uniqueFieldsToReset);

		log({
			visibility: {
				initial: variableVisibilityMap,
				new: newVariableVisibilityMap
			},
			filtering: {
				initial: variableFilteringMap,
				new: newVariableFilteringMap
			},
			formValues
		});

		// if dependenciesCheck result eventually renders one of the variable NOT VISIBLE then all

		// UPDATE `variableVisibilityMap` STATE IF NEW CHANGES
		if (!isEqual(variableVisibilityMap, newVariableVisibilityMap)) {
			log('sets visibility');

			setVariableVisibilityMap(newVariableVisibilityMap);
		}
		// UPDATE `variableFilteringMap` STATE IF NEW CHANGES
		if (!isEqual(variableFilteringMap, newVariableFilteringMap)) {
			log('sets filtering');

			setVariableFilteringMap(newVariableFilteringMap);
		}
	}

	function resetFields(variableNames: string[]) {
		variableNames.forEach(variableName => resetField(variableName));
	}

	function resetField(variableName: string) {
		const defaultValue = getFieldDefaultValue(variableName);

		setValue(variableName, defaultValue, {
			shouldDirty: true,
			shouldValidate: true
		});
	}

	function getFieldDefaultValue(variableName: string): DynamicFormValue {
		const isCategoryMultiple =
			variablesMap[variableName].type === VariableType.CategoryMultiple;

		return isCategoryMultiple ? [] : '';
	}

	////////////////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////////////////

	// INIT RULES
	useEffect(() => {
		// VARIABLES AND DEPENDENCIES ARE NOT FETCHED - DO NOTHING
		if (!(areVariablesFetched && areDependenciesFetched)) return;

		// UPDATE ENTRY MODE AND ENTRIES ARE NOT FETCHED - DO NOTHING
		if (isOnUpdateEntryRoute) return;

		// Rules are disabled - do nothing
		if (!scopeActive) return;

		log(translate(dict => dict.dataset.dependenciesMapChecker.buildInitial));

		// DEBOUNCE IN JS CALL STACK
		const timeout = setTimeout(buildInitialDependenciesCheck);

		return () => clearTimeout(timeout);
	}, [areVariablesFetched, areDependenciesFetched, isOnUpdateEntryRoute, scopeActive]);

	const prevRevision = usePrevious(revision);
	useEffect(() => {
		if (!scopeActive) return;

		if (prevRevision === undefined) return;

		if (isEqual(prevRevision, revision)) return;

		if (!(revision && revision.changes.data !== null)) return;

		log(translate(dict => dict.dataset.dependenciesMapChecker.revisionChanged));

		// DEBOUNCE IN JS CALL STACK
		const timeout = setTimeout(buildInitialDependenciesCheck);

		return () => clearTimeout(timeout);
	}, [revision, scopeActive]);

	const prevEntry = usePrevious(entry);
	useEffect(() => {
		if (!scopeActive) return;

		if (entry && !prevEntry) return buildInitialDependenciesCheck();
		if (prevEntry === undefined) return;

		if (isEqual(prevEntry, entry)) return;

		if (!entry) return;

		log(translate(dict => dict.dataset.dependenciesMapChecker.entryChanged));

		// DEBOUNCE IN JS CALL STACK
		const timeout = setTimeout(buildInitialDependenciesCheck);

		return () => clearTimeout(timeout);
	}, [entry, scopeActive]);

	const prevSubEntryId = usePrevious(subEntryId);
	useEffect(() => {
		if (!setName) return;
		if (!scopeActive) return;

		if (subEntryId && !prevSubEntryId) return buildInitialDependenciesCheck();
		if (prevSubEntryId === undefined) return;

		if (isEqual(prevSubEntryId, subEntryId)) return;

		log(translate(dict => dict.dataset.dependenciesMapChecker.subEntryChanged));

		// DEBOUNCE IN JS CALL STACK
		const timeout = setTimeout(buildInitialDependenciesCheck);

		return () => clearTimeout(timeout);
	}, [setName, subEntryId, scopeActive]);

	// FIELD CHANGE LISTENER - RUN DEP CHECKS ON CHANGE
	useCustomEventListener(entryFormFieldChangeEvent, {
		onListen: payload => {
			const { fieldName } = payload;

			const dependenciesToRerun = dependencyNamesByVariableName[fieldName];

			log('handleFieldChange()', payload, { dependenciesToRerun });

			if (dependenciesToRerun) runDependenciesCheck(dependenciesToRerun);
		},
		onStartListening: () =>
			log(translate(dict => dict.dataset.dependenciesMapChecker.fieldChangeListener)),
		listen:
			areVariablesFetched &&
			areDependenciesFetched &&
			(isOnUpdateEntryRoute ? areEntriesFetched : true) &&
			scopeActive
	});

	// RE-RUN DEPENDENCIES CHECK EVENT AFTER ENTRY DRAFT WAS APPLIED
	useCustomEventListener(entryFormDependenciesCheckEvent, {
		onListen: payload => {
			const { fieldNames } = payload;

			const rawDependenciesToRerun: string[] = [];

			fieldNames.forEach(fieldName => {
				const dependencyNames = dependencyNamesByVariableName[fieldName];

				if (dependencyNames) rawDependenciesToRerun.push(...dependencyNames);
			});

			const uniqueDependenciesToRerun = [...new Set(rawDependenciesToRerun)];

			const dependenciesToRerun: string[] = dependencyNames.filter(dependencyName =>
				uniqueDependenciesToRerun.includes(dependencyName)
			);

			log('handleApplyEntryDraft()', payload, { dependenciesToRerun });

			if (uniqueDependenciesToRerun.length) {
				runDependenciesCheck(dependenciesToRerun, { shouldResetFields: false });
			}
		},
		onStartListening: () =>
			log(translate(dict => dict.dataset.dependenciesMapChecker.entryDraftApplied)),
		listen: areVariablesFetched && areDependenciesFetched && isOnUpdateEntryRoute && scopeActive
	});

	function buildInitialDependenciesCheck() {
		log('buildInitialDependenciesCheck()');

		runDependenciesCheck(dependencyNames, { shouldResetFields: true });
	}

	function buildDependenciesMap(dependencies: Dependency[]): GenericMap<Dependency> {
		const map: GenericMap<Dependency> = {};

		dependencies.forEach(dependency => (map[dependency.dependencyName] = dependency));

		return map;
	}

	return null;
}

export const DependenciesMapChecker = withMemo(DependenciesMapCheckerComponent, [
	'variableVisibilityMapState',
	'variableFilteringMapState',
	'setName'
]);

function arrayIntersection(array1: string[], array2: string[]) {
	return array1.filter(value => array2.includes(value)) ?? [];
}
