import { Auth } from 'aws-amplify';
import { isAfter } from 'date-fns';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { methods as accountMethods } from 'api/account/subscription/api';
import { RequestError } from 'api/types';
import { Dictionary } from 'environment';
import { LedidiStatusCode, StorageKeys } from 'types/index';

import { isServerErrorCode } from './helpers';
import { SignOutStatus } from 'helpers/signOutStatus';
import { authLogoutOrManualCleanup } from 'helpers/autoLogout';
import { getTranslation } from 'hooks/store/ui/useTranslation';
import { parseJwt } from 'helpers/amplify/amplifyHelpers';

const DEFAULT_REQUEST_TIMEOUT = 30000;
const CANCELED_REQUEST_MESSAGE = 'canceled';

export enum RequestTypes {
	GET,
	POST,
	PUT
}
class RepeatingAuthError extends Error {}

export class CanceledRequestError extends Error {
	static message = CANCELED_REQUEST_MESSAGE;
}

function Error400(message: string) {
	const error = new Error(message);
	error.name = '400';
	return error;
}

Error400.prototype = Object.create(Error.prototype);

axios.interceptors.request.use(async (requestConfig: AxiosRequestConfig) => {
	const { withCredentials = true } = requestConfig;
	let sessionValid = true;

	const isFederatedUser = localStorage.getItem(StorageKeys.FederatedUILogin) === 'true';
	if (isFederatedUser) {
		const token = localStorage.getItem(StorageKeys.IdToken);
		if (token) {
			const { exp: expiryTime } = parseJwt(token);
			const dateNow = new Date().getTime();

			sessionValid = isAfter(expiryTime * 1000, dateNow);

			if (!sessionValid) {
				await authLogoutOrManualCleanup(true);
			}
		}
	}

	if (withCredentials && sessionValid) {
		try {
			// TODO: later check if possible to do automatic refresh for refresh token, not critical anyway
			// see use case 32 here: https://github.com/aws-amplify/amplify-js/tree/master/packages/amazon-cognito-identity-js
			// + https://github.com/aws-amplify/amplify-js/wiki/FAQ#will-amplify-automatically-refresh-the-session
			// if ((AWS?.config?.credentials as AWS.Credentials)?.needsRefresh()) {
			// 	console.log('Should call refreshSession');
			// }
			if (isFederatedUser) {
				const token = localStorage.getItem(StorageKeys.IdToken);
				if (token) {
					requestConfig.data.accessTokenStr = token;
					SignOutStatus.setStatusStarted(false);
				}
			} else {
				const session = await Auth.currentSession();
				const idToken = session.getIdToken().getJwtToken();
				if (requestConfig.data.method === accountMethods.updateAccount) {
					requestConfig.data.userAccessTokenStr = session.getAccessToken().getJwtToken();
				}

				localStorage.setItem(StorageKeys.IdToken, idToken);

				// check if is temporary prom user
				// @ts-ignore
				if (session.idToken.payload['custom:role'] === 'temppromsuser') {
					localStorage.removeItem(StorageKeys.LoginCompleted);
				}

				requestConfig.data.accessTokenStr = idToken;

				SignOutStatus.setStatusStarted(false);
			}
		} catch (e: any) {
			console.error('Failed to read/refresh current session', e.message || e);
			if (isFederatedUser) {
				await authLogoutOrManualCleanup(true);
				SignOutStatus.setStatusStarted(true);
				throw new Error('You got signed out');
			}
			if (!isFederatedUser && !SignOutStatus.getStatusStarted()) {
				SignOutStatus.setStatusStarted(true);
				await authLogoutOrManualCleanup();
				throw new Error('You got signed out');
			} else {
				console.log(
					'Skip SignOut at phase. Was started:',
					SignOutStatus.getStatusStarted()
				);
				throw new RepeatingAuthError();
			}
		}
	}

	return requestConfig;
});

type AxiosResponseWithErrors = AxiosResponse<RequestError>;

axios.interceptors.response.use(
	async (response: AxiosResponseWithErrors): Promise<AxiosResponseWithErrors> => {
		const { data } = response;

		/**
		 * BYPASS SHOWING SERVER ERROR MESSAGE AND THROWING OF GENERAL ERROR
		 * THIS WILL BE ADJUSTED IN THE FUTURE - AN EPIC WILL BE CREATED FOR API RESPONSES HARMONIZATION
		 */

		if (
			data.ledidiStatusCode === LedidiStatusCode.UniqueFieldValue ||
			data.ledidiStatusCode === LedidiStatusCode.DependenciesViolation
		) {
			return response;
		}

		if (
			data.ledidiStatusCode === LedidiStatusCode.JADBioTokenExpired ||
			(data.message && data.message.includes('Authentication failed'))
		) {
			throw Error(LedidiStatusCode.JADBioTokenExpired);
		}

		if (data.ledidiStatusCode && data.ledidiStatusCode === LedidiStatusCode.CorruptedData) {
			throw new Error(getTranslation(({ analysis }) => analysis.errors.corruptedData));
		}

		const is403ServerError = isServerErrorCode(data, 403);
		const is400ServerError = isServerErrorCode(data, 400);

		if (is403ServerError) {
			if (
				data.ledidiStatusCode === LedidiStatusCode.ErrorLicence ||
				data.ledidiStatusCode === LedidiStatusCode.ErrorLicenceOther
			)
				return response;
			throw new Error(data.message || data.errorMessage || Dictionary.errors.api.noAccess);
		} else if (is400ServerError && (data.message || data.errorMessage)) {
			if (data.errors) return response;
			// throw custom 400 error with message
			throw Error400(data.message || data.errorMessage || '');
		}

		return response;
	},
	error => Promise.reject(error)
);

export async function sendRequest<Request = any, Response = any>(
	url: string,
	body: Request,
	config?: AxiosRequestConfig,
	requestType?: RequestTypes
): Promise<Response> {
	const conf = {
		timeout: DEFAULT_REQUEST_TIMEOUT,
		headers: {
			'Content-Type': 'application/json'
		},
		...config
	};

	/**
	 * Handles throttling API priority on the server (3rd party users vs ledidi users)
	 */
	if (process.env.REACT_APP_X_API_KEY) {
		conf.headers['x-api-key'] = process.env.REACT_APP_X_API_KEY;
	}

	try {
		if (requestType === RequestTypes.PUT) {
			return await axios.put(url, body, conf);
		}

		return await axios.post(url, body, conf);
	} catch (e: any) {
		// do not propagate error further if request is canceled
		if (e.message === CanceledRequestError.message) {
			return new Promise<AxiosResponse | any>((_, reject) => {
				// empty message will be caught in action flow but no toast dispatched
				reject(new CanceledRequestError());
			});
		}

		if (e instanceof RepeatingAuthError) {
			// this very specific error type should not propagate further!
			return new Promise<AxiosResponse | any>(() => undefined);
		}

		throw e;
	}
}
