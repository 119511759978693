import { useMemo } from 'react';
import { Variable } from 'api/data/variables';
import { getEntriesValidationSchema, withCustomSuffix } from 'helpers/entries';
import { DynamicFormValues, DynamicFormFiles, Entry } from 'store/data/entries';
import { VariablesMap } from 'store/data/variables';
import { Revision } from 'store/data/revisions';
import { Form } from 'store/data/forms';

import { TranslateFunction, useTranslation } from 'hooks/store/ui/useTranslation';
import { useSetRevisionId } from '../revisions/useSetRevisionId';
import { getUsedVariablesInForms } from 'helpers/forms';
import { variablesDataArrayIterator } from 'helpers/variables';
import { useForms } from '../forms/useForms';
import { useRevision } from '../revisions/useRevision';
import { useRevisionId } from '../revisions/useRevisionId';
import { useSetRevision } from '../revisions/useSetRevision';
import { useVariables } from '../variables/useVariables';
import { useEntry } from './useEntry';
import { VariableType } from 'types/data/variables/constants';

interface GetFormDataProps {
	variables: Variable[];
	entry: Entry | null;
	revision?: Revision;
	revisionId?: string | null;
	translate: TranslateFunction;
}

function getFormData({ variables, entry, revision, revisionId, translate }: GetFormDataProps) {
	const values: DynamicFormValues = {};
	const files: DynamicFormFiles = {
		fileIds: []
	};
	variables.forEach(variable => {
		const { type, name, categories, fixedCategories } = variable;

		const isRadioGroup = type === VariableType.Category;
		const isCheckboxGroup = type === VariableType.CategoryMultiple;
		const isCategory = isRadioGroup || isCheckboxGroup;
		const isFile = type === VariableType.File;

		const allowCreate = !fixedCategories;

		const defaultValue = isCheckboxGroup ? [] : '';

		// INITIALIZE DEFAULT VALUE
		values[name] = defaultValue;

		const entryValue = entry?.[name] ?? null;

		if (entryValue !== undefined && entryValue !== null) {
			const categoryValues = categories.map(c => c.value);

			// CATEGORY MULTIPLE (string[])
			if (Array.isArray(entryValue)) {
				const computedEntryValue = entryValue.filter(value =>
					categoryValues.includes(value)
				);

				values[name] = computedEntryValue;
			}
			// SIMPLE (string)
			else {
				values[name] = entryValue;
			}
		}

		// INITIATE CUSTOM VALUE FIELD FOR CATEGORY VARIABLE
		if (isCategory && allowCreate) {
			values[withCustomSuffix(name)] = '';
		}

		// APPLY REVISION DATA IF `revisionId` IS SET
		if (revisionId) {
			// REVISION DATA IS FETCHED
			if (revision && revision.changes.data !== null) {
				const { data } = revision.changes;

				const revisionEntryValue = data[name];

				// CHANGE FOUND - SHOW VALUE
				if (revisionEntryValue !== undefined && revisionEntryValue !== null) {
					values[name] = revisionEntryValue;
				}
				// SHOW EMPTY VALUE
				else {
					values[name] = defaultValue;
				}
			}
			// SHOW EMPTY VALUES UNTIL REVISION DATA IS FETCHED
			else {
				values[name] = defaultValue;
			}
		}

		// INIT FILES OBJECT
		if (isFile && values[name]) {
			const value = values[name] as string;
			// APPEND FILE ID
			files.fileIds.push(value);
		}
	});

	const { validationSchema } = getEntriesValidationSchema({
		variables,
		translate
	});

	return { values, validationSchema, files };
}

export function useEntriesForm() {
	const { translate } = useTranslation();

	const [
		{
			data: { variablesMap, variablesDataArray }
		}
	] = useVariables({
		initial: true,
		lazy: true,
		omit: { promGenerated: true }
	});

	const [{ data: entry }] = useEntry({ lazy: true });
	const [{ data: revision }] = useRevision({ lazy: false });
	const [revisionId] = useRevisionId();

	const [{ data: forms }] = useForms({ lazy: true });

	const activeForms = useMemo(() => forms.filter(form => form.active), [forms]);
	const variableNamesInForms = useMemo(() => getUsedVariablesInForms(activeForms), [activeForms]);
	const hasActiveForms = activeForms.length > 0;

	const variablesInForms = useMemo(
		() =>
			variableNamesInForms.reduce<Variable[]>((acc, variableName) => {
				const variable = variablesMap[variableName];

				if (variable) acc.push(variable);

				return acc;
			}, []),
		[variableNamesInForms, variablesMap]
	);

	const variablesInDefaultForm = useMemo(() => {
		const variables: Variable[] = [];

		variablesDataArrayIterator(
			variablesDataArray,
			// VARIABLE
			variable => variables.push(variable),
			// GROUP
			group => group.groupVariables.forEach(variable => variables.push(variable)),
			// VARIABLE SET - OMIT
			() => null
		);

		return variables;
	}, [variablesDataArray]);

	const variablesForInitialFormData = hasActiveForms ? variablesInForms : variablesInDefaultForm;

	const formData = useMemo(
		() =>
			getFormData({
				variables: variablesForInitialFormData,
				entry,
				revision,
				revisionId,
				translate
			}),
		[variablesForInitialFormData, entry, revision, revisionId]
	);

	return formData;
}

export function useSeriesEntriesForm(
	input: {
		isNotCurrentEntry?: boolean;
		forms: Form[];
		variables: Variable[];
		variablesMap: VariablesMap;
	},

	entry: Entry | null
) {
	const { forms, variables, variablesMap } = input;

	const { translate } = useTranslation();

	const activeForms = useMemo(() => forms.filter(form => form.active), [forms]);
	const variableNamesInForms = useMemo(() => getUsedVariablesInForms(activeForms), [activeForms]);
	const hasActiveForms = activeForms.length > 0;
	const [{ data: revision }] = useSetRevision({ lazy: false });
	const [revisionId] = useSetRevisionId();

	const variablesInForms = useMemo(
		() =>
			variableNamesInForms.reduce<Variable[]>((acc, variableName) => {
				const variable = variablesMap[variableName];

				if (variable) acc.push(variable);

				return acc;
			}, []),
		[variableNamesInForms, variablesMap]
	);

	const variablesForInitialFormData = hasActiveForms ? variablesInForms : variables;

	const formData = useMemo(() => {
		if (input.isNotCurrentEntry) {
			return getFormData({
				variables: variablesForInitialFormData,
				entry,
				translate
			});
		}
		return getFormData({
			variables: variablesForInitialFormData,
			entry,
			revision,
			revisionId,
			translate
		});
	}, [variablesForInitialFormData, entry, revision, revisionId]);

	return formData;
}
