import { Amplify, Auth } from 'aws-amplify';
import { AWSIoTProvider } from '@aws-amplify/pubsub';
import { Logger } from '@aws-amplify/core';
import { StorageKeys } from 'types/index';
import { parseJwt } from 'helpers/amplify/amplifyHelpers';

const DEBUG_ENABLED = false;

// Remove when feature is done
if (DEBUG_ENABLED) {
	new Logger('SignIn');
	Amplify.Logger.LOG_LEVEL = 'DEBUG';
}

const {
	REACT_APP_REGION,
	REACT_APP_USER_POOL_ID,
	REACT_APP_IDENTITY_POOL_ID,
	REACT_APP_CLIENT_ID,
	REACT_APP_PUBSUB_ENDPOINT,
	REACT_APP_FEDERATED_CLIENT_IDS,
	REACT_APP_SSO_URL_BASE
} = process.env;

if (DEBUG_ENABLED) {
	console.log({
		REACT_APP_REGION,
		REACT_APP_USER_POOL_ID,
		REACT_APP_IDENTITY_POOL_ID,
		REACT_APP_CLIENT_ID,
		REACT_APP_PUBSUB_ENDPOINT,
		REACT_APP_FEDERATED_CLIENT_IDS
	});
}

// get current URL for tokens and redirect
const { href, search, origin } = window.location;
// get current URL parameters to check if SSO redirect shortener
const params = new URLSearchParams(search);
// check if should redirect to hosted UI
const provider = params.get('sso');
// redirect path should be whatever client needs but remove sso url param to not cause infinite loop
const redirectPath = origin.split('?sso=')[0];
const shouldReload = params.get('tokenExpired');
const ssoBaseURL = REACT_APP_SSO_URL_BASE ?? 'sso.ledidi.no';

if (shouldReload) {
	window.location.reload();
	window.location.replace('/login');
}
// SSO clients
const ssoClients: { [key: string]: string } = REACT_APP_FEDERATED_CLIENT_IDS
	? JSON.parse(REACT_APP_FEDERATED_CLIENT_IDS)
	: {};

const ssoError = href.includes('#error_description=');
if (ssoError) {
	const phoneNumberOrEmailMissing = href.includes('phone_number') || href.includes('email');
	const customError = phoneNumberOrEmailMissing
		? null
		: href.split('#error_description=')[1].split('&error')[0];
	const errorMessage = href.split('&error=')[1];

	alert(
		`An error occured during single-sign on.\nUnknown/invalid scope(s): ${
			customError ?? '[phone, email]'
		}\nError: ${errorMessage}.\nRedirecting to login page.`
	);

	const baseUrl = href.split('#')[0];
	window.location.replace(baseUrl);
}

const providerIsSSOClient =
	provider !== null && Object.keys(ssoClients).includes(provider.toLowerCase());

if (providerIsSSOClient) {
	window.location.href = `https://${ssoBaseURL}/login?client_id=${
		ssoClients[provider.toLowerCase()]
	}&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=${redirectPath}`;
}

let clientId: string = localStorage.getItem(StorageKeys.FederatedLoginClientID)
	? (localStorage.getItem(StorageKeys.FederatedLoginClientID) as string)
	: REACT_APP_CLIENT_ID!;

// If SSO login, get the client id from the token
if (href.includes('access_token=') && href.includes('id_token=')) {
	const access_token = href.split('access_token=')[1].split('&')[0];
	clientId = parseJwt(access_token).client_id;
}

const isLocalhost = Boolean(
	window.location.hostname === 'localhost' ||
		// [::1] is the IPv6 localhost address.
		window.location.hostname === '[::1]' ||
		// 127.0.0.1/8 is considered localhost for IPv4.
		window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

// need to set oauth as optional
interface CognitoConfig {
	Auth: any;
	Analytics: any;
	oauth?: any;
}

// initial config used for normal app login
// cookies are never required for this
let awsConfig: CognitoConfig = {
	Auth: {
		mandatorySignIn: true,
		region: REACT_APP_REGION,
		userPoolId: REACT_APP_USER_POOL_ID,
		identityPoolId: REACT_APP_IDENTITY_POOL_ID,
		userPoolWebClientId: clientId
	},
	Analytics: {
		disabled: true
	}
};

// Include oauth key only if we need to validate token,
// otherwise we break login flow
const shortURL = process.env.REACT_APP_SSO_SHORT_URL_BASE ?? 'app-systest.ledidi.no';
const localStorageProviderName = localStorage.getItem('SSOProvider');
const signoutRedirectShortURL = `https://${shortURL}/login?sso=${
	localStorageProviderName ? localStorageProviderName : provider ? provider.toLowerCase() : ''
}`;

if (
	(href.includes('access_token=') && href.includes('id_token=')) ||
	localStorage.getItem(StorageKeys.FederatedUILogin) === 'true'
) {
	awsConfig = {
		...awsConfig,
		oauth: {
			// domain is the same as where user has landed
			domain: origin.split('//')[1],
			scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
			// need to set as localhost for development
			redirectSignIn: isLocalhost ? 'localhost' : origin.split('//')[1],
			// need to set as localhost for development
			redirectSignOut: signoutRedirectShortURL,
			// redirectSignOut: isLocalhost ? 'localhost' : origin.split('//')[1],
			// client id is taken from token
			clientId,
			responseType: 'token' // never use 'code'
		}
	};
}

Amplify.configure(awsConfig);

Amplify.addPluggable(
	new AWSIoTProvider({
		aws_pubsub_region: REACT_APP_REGION,
		aws_pubsub_endpoint: REACT_APP_PUBSUB_ENDPOINT
	})
);

if (href.includes('access_token=') && href.includes('id_token=')) {
	// get tokens from SSO redirect URL
	const access_token = href.split('access_token=')[1].split('&')[0];
	const id_token = href.split('id_token=')[1].split('&')[0];

	// Remove when feature is ready
	if (DEBUG_ENABLED) {
		// used for development on PG2. URL changes, so you might not get the chance to copy the tokens
		localStorage.setItem('COMPLETE_URL', href.split('#')[1]);
		// log tokens and parsed tokens
		console.log('tokens', {
			access_token,
			id_token,
			parsed_access_token: parseJwt(access_token),
			parsed_id_token: parseJwt(id_token)
		});
	}

	const expiresAt = new Date().getTime() + 2.628e9;

	if (access_token) {
		const token = parseJwt(id_token);
		// this does the actual oauth login
		Auth.federatedSignIn(
			token.iss.replace('https://', ''),
			{
				token: id_token,
				expires_at: expiresAt ?? token.expiresAt,
				identity_id: token.refreshToken
			},
			token
		)
			.then(() => {
				// this is the user received from federatedSignIn response
				Auth.currentAuthenticatedUser()
					.then(user => {
						const providerName = user.identities[0].providerName;

						// set up required localStorage keys
						localStorage.setItem('SSOProvider', providerName);
						localStorage.removeItem(StorageKeys.Username);
						localStorage.removeItem(StorageKeys.FederatedUILogin);
						localStorage.removeItem(StorageKeys.IdToken);
						localStorage.removeItem(StorageKeys.LoginCompleted);
						localStorage.removeItem(StorageKeys.LoginStep);
						localStorage.removeItem(StorageKeys.PatientLoginCompleted);
						localStorage.setItem(StorageKeys.Username, user['cognito:username']);
						localStorage.setItem(StorageKeys.FederatedUILogin, 'true');
						localStorage.setItem(StorageKeys.IdToken, user.token);
						localStorage.setItem(StorageKeys.LoginCompleted, 'true');
						localStorage.setItem(
							StorageKeys.FederatedLoginClientID,
							parseJwt(access_token).client_id
						);
					})
					.then(() => {
						window.location.href = '/projects';
					})
					.catch(e => {
						// We should show toast here with error message
						console.log('=== Auth.currentAuthenticatedUser Failed', e);
					});
			})
			// We should show toast here with error message
			.catch(e => console.log('=== FederatedSignIn Failed: ', e));
	}
}
