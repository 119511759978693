import { useEffect, useMemo } from 'react';
import { cloneDeep } from 'lodash';

import { CrosstabAnalysisV2 } from 'api/data/analyses';
import {
	AnalysisStatisticsWrapper,
	AnalysisViewContainer,
	AnalysisErrorWrapper,
	AnalysisExportWrapper
} from 'components/Analysis/Analyses';
import { ExportFileNames, FileType } from 'types/index';
import { ZingChartExportTypes } from 'types/charts';

import { CrosstabTableV2 } from './CrosstabTableV2';
import { CrosstabStatisticsV2 } from './CrosstabStatisticsV2/CrosstabStatisticsV2';
import { CrosstabBarChartV2 } from './CrosstabBarChartV2';
import { CrosstabSunburstChartV2 } from './CrosstabSunburstChartV2';
import { AnalysisChartContainer } from '../UI';
import { exportSvg } from 'helpers/analysis';
import { downloadFile, getExportFileName } from 'helpers/generic';
import {
	buildAggregationRuleNameToAggregatorVariableMap,
	mapVariableCategoryValueToLabel
} from 'helpers/variables';
import {
	useAreFiltersValid,
	useProject,
	useAnalysisActiveTab,
	useAnalysesActiveColum,
	useFullscreenAnalysis,
	useVariablesDataSelectItems,
	useVariablesData
} from 'hooks/store';
import { AnalysisType } from 'api/data/analyses/constants';
import { exportCrosstabV2 } from 'helpers/analysis/exportCrosstabV2';

interface Props {
	analysis: CrosstabAnalysisV2;
	exporting: boolean;
	loading: boolean;
}

const NO_DATA = {
	columnVariable: { name: '' },
	rowVariable: { name: '' },
	columnLabels: [],
	counts: [],
	percentages: [],
	rowLabels: []
};

export function CrosstabViewV2({ analysis, exporting, loading }: Props) {
	const areFiltersValid = useAreFiltersValid();
	const [{ data: project }] = useProject();

	const variablesData = useVariablesData();

	const {
		id,
		input,
		options: { configPanel, chartLegend = true, showCategoryLabels },
		output: { dataset }
	} = analysis;

	const { series } = input;

	const variablesDataSelectItems = useVariablesDataSelectItems(variablesData, {
		omitVariables: [],
		series
	});

	const { selectItemsLabelMap } = variablesDataSelectItems;
	const [activeTab] = useAnalysisActiveTab(id);
	const [activeColumn] = useAnalysesActiveColum();
	const [fullscreen] = useFullscreenAnalysis();

	const crosstabGroupedId = ZingChartExportTypes.CrosstabGrouped + id;
	const crosstabStackedId = ZingChartExportTypes.CrosstabStacked + id;
	const crosstabSunburstId = ZingChartExportTypes.CrosstabSunburst + id;

	useEffect(() => {
		// 8818
		if (exporting && project) {
			if (activeTab === 0) {
				const data = exportCrosstabV2(parsedDataset ?? NO_DATA);
				downloadFile(
					data,
					getExportFileName(
						ExportFileNames.CrosstabTable,
						project.projectId,
						project.projectName
					),
					FileType.Csv
				);
			} else if (activeTab === 1) {
				exportSvg(
					crosstabGroupedId,
					getExportFileName(
						ExportFileNames.CrosstabGroupedChart,
						project.projectId,
						project.projectName
					)
				);
			} else if (activeTab === 2) {
				exportSvg(
					crosstabStackedId,
					getExportFileName(
						ExportFileNames.CrosstabStackedChart,
						project.projectId,
						project.projectName
					)
				);
			} else if (activeTab === 3) {
				exportSvg(
					crosstabSunburstId,
					getExportFileName(
						ExportFileNames.CrosstabSunburstChart,
						project.projectId,
						project.projectName
					)
				);
			}
		}
	}, [exporting]);

	const areStatisticsEnabled = Object.values(input.statistics).some(value => value);
	const renderStatistics = areFiltersValid && areStatisticsEnabled;

	const scalesLabels = {
		labelX: selectItemsLabelMap[dataset.data?.columnVariable?.name ?? ''] ?? '',
		labelY: selectItemsLabelMap[dataset.data?.rowVariable?.name ?? ''] ?? ''
	};

	const legendHeader = selectItemsLabelMap[dataset.data?.rowVariable?.name ?? ''] ?? '';

	const { variablesMap, variableSetsMap } = variablesData;

	const aggregationToVariableName =
		buildAggregationRuleNameToAggregatorVariableMap(variableSetsMap);

	const { parsedDataset } = useMemo(() => {
		const parsedDataset = cloneDeep(dataset.data);
		if (parsedDataset) {
			const columnVariable =
				variablesMap[parsedDataset.columnVariable?.name] ??
				variablesMap[
					aggregationToVariableName[parsedDataset.columnVariable?.name].aggregator
						.variableName
				];
			const rowVariable =
				variablesMap[parsedDataset.rowVariable?.name] ??
				variablesMap[
					aggregationToVariableName[parsedDataset.rowVariable?.name].aggregator
						.variableName
				];

			if (showCategoryLabels) {
				parsedDataset.columnLabels.forEach((label, index) => {
					parsedDataset.columnLabels[index] =
						mapVariableCategoryValueToLabel(label, columnVariable) ?? label;
				});

				parsedDataset.rowLabels.forEach((label, index) => {
					parsedDataset.rowLabels[index] =
						mapVariableCategoryValueToLabel(label, rowVariable) ?? label;
				});
			}
		}
		return { parsedDataset };
	}, [dataset, variablesMap, showCategoryLabels]);

	const disableInteractions = activeTab === 3 && activeColumn !== 1 && !fullscreen;

	return (
		<AnalysisViewContainer
			disableInteractions={disableInteractions}
			isConfigPanelOpen={configPanel.open}
			loading={loading}
		>
			<AnalysisErrorWrapper analysis={analysis} analysisType={AnalysisType.CrosstabV2}>
				{parsedDataset ? (
					<>
						{activeTab === 0 && <CrosstabTableV2 dataset={parsedDataset} />}
						{activeTab === 1 && (
							<>
								<AnalysisChartContainer
									fullscreen={fullscreen}
									activeColumn={activeColumn}
								>
									<CrosstabBarChartV2
										dataset={parsedDataset}
										isLegendEnabled={chartLegend}
										legendHeader={legendHeader}
										scalesLabels={scalesLabels}
										fullscreen={fullscreen}
										activeColumn={activeColumn}
									/>
								</AnalysisChartContainer>
								<AnalysisExportWrapper>
									<CrosstabBarChartV2
										dataset={parsedDataset}
										id={crosstabGroupedId}
										isForExport
										isLegendEnabled={chartLegend}
										legendHeader={legendHeader}
										scalesLabels={scalesLabels}
										fullscreen={fullscreen}
										activeColumn={activeColumn}
									/>
								</AnalysisExportWrapper>
							</>
						)}

						{activeTab === 2 && (
							<>
								<AnalysisChartContainer
									fullscreen={fullscreen}
									activeColumn={activeColumn}
								>
									<CrosstabBarChartV2
										dataset={parsedDataset}
										isLegendEnabled={chartLegend}
										stacked
										legendHeader={legendHeader}
										scalesLabels={scalesLabels}
										fullscreen={fullscreen}
										activeColumn={activeColumn}
									/>
								</AnalysisChartContainer>
								<AnalysisExportWrapper>
									<CrosstabBarChartV2
										id={crosstabStackedId}
										dataset={parsedDataset}
										isLegendEnabled={chartLegend}
										stacked
										isForExport
										legendHeader={legendHeader}
										scalesLabels={scalesLabels}
										fullscreen={fullscreen}
										activeColumn={activeColumn}
									/>
								</AnalysisExportWrapper>
							</>
						)}

						{activeTab === 3 && (
							<>
								<AnalysisChartContainer
									fullscreen={fullscreen}
									activeColumn={activeColumn}
								>
									<CrosstabSunburstChartV2
										dataset={parsedDataset}
										fullscreen={fullscreen}
									/>
								</AnalysisChartContainer>
								<AnalysisExportWrapper>
									<CrosstabSunburstChartV2
										id={crosstabSunburstId}
										dataset={parsedDataset}
										isForExport
									/>
								</AnalysisExportWrapper>
							</>
						)}

						{renderStatistics && (
							<AnalysisStatisticsWrapper>
								<CrosstabStatisticsV2 analysis={analysis} />
							</AnalysisStatisticsWrapper>
						)}
					</>
				) : (
					<></>
				)}
			</AnalysisErrorWrapper>
		</AnalysisViewContainer>
	);
}
