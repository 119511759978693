import { createContext, useContext } from 'react';
import { SegmentComponent } from 'app/SegmentAnalytics';

interface TrackingProviderContext {
	track: (args: { eventName: string; data?: any }) => void;
}

const TrackingContext = createContext<TrackingProviderContext | null>(null);

export const track = ({ eventName, data }: { eventName: string; data?: any }) => {
	SegmentComponent?.track(eventName, data, {
		context: { ip: '0.0.0.0' }
	});
};

export const TrackingProvider = ({ children }: { children: React.ReactNode }) => {
	return <TrackingContext.Provider value={{ track }}>{children}</TrackingContext.Provider>;
};

export const useTracking = () => {
	const tracking = useContext(TrackingContext);
	if (!tracking) {
		throw new Error('useTracking must be used within a TrackingProvider');
	}
	return tracking;
};
